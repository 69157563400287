const locales = {
  kr: {
    name: 'KR',
    text: '한국',
  },
  us: {
    name: 'US',
    text: 'USA',
  },
}

export { locales }
