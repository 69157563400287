var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container"},[(_vm.showHeader)?_c('div',{staticClass:"header"},[_c('img',{attrs:{"src":_vm.iconSrc}}),_c('div',[_vm._v(_vm._s(_vm.tableName))])]):_vm._e(),_c('div',{staticClass:"table"},[_c('div',{staticClass:"top-row"},[_vm._l((_vm.fields),function(field,i){return _c('div',{key:i,staticClass:"field"},[_c('div',[_vm._v(" "+_vm._s(field.name)+" ")])])}),_c('div',{staticClass:"accept-button-header"})],2),_c('div',{staticClass:"middle-box"},[_vm._l((_vm.contents),function(content,i){return _c('div',{key:`o-${i}`,staticClass:"middle-row",style:({
            'border-bottom':
              i === _vm.contents.length - 1 ? 'solid 1px #cccccc' : 'none',
          })},[_vm._l((_vm.fields),function(field,k){return _c('div',{key:k},[(field.type == 'event')?_c('div',{staticClass:"field"},[_vm._v(" "+_vm._s(content[field.key])+" ")]):(field.type == 'price')?_c('div',{staticClass:"field",on:{"click":function($event){_vm.tableName == _vm.$t('views.content-detail.table.offers.title')
                  ? _vm.toEtherscan(content.txHash)
                  : _vm.toEtherscan(content.tx_hash)}}},[_vm._v(" "+_vm._s(content[field.key] ? _vm.weiToEther(content[field.key]) + ' ETH' : '')+" "),_c('img',{attrs:{"src":require("../../assets/icons/launch-grey.svg")}})]):(field.type == 'date')?_c('div',{staticClass:"field"},[_vm._v(" "+_vm._s(_vm.convertDay(parseInt(content[field.key])))+" ")]):(field.type == 'countdown')?_c('CountDownTimer',{staticClass:"field",attrs:{"endDate":new Date(content[field.key] * 1000),"isAccepted":content.isAccepted,"isFirstLoading":true}}):(field.type == 'member')?_c('div',{staticClass:"field"},[(!content[field.key])?_c('div',[_c('ContentsProfile',{attrs:{"member":content[field.key],"needUserName":true}})],1):_c('router-link',{attrs:{"to":{
                  name: 'UserOrArtist',
                  params: {
                    id: content[field.key] ? content[field.key].username : '',
                  },
                }}},[_c('ContentsProfile',{attrs:{"member":content[field.key],"needUserName":true}})],1)],1):_vm._e()],1)}),(
              _vm.tableName === _vm.$t('views.content-detail.table.offers.title') &&
              new Date(content.deadline * 1000) > new Date() &&
              _vm.isCurrentUserTokenOwner &&
              !content.isAccepted
            )?_c('div',{staticClass:"accept-button"},[_c('button',{on:{"click":function($event){return _vm.accept(content.from.wallet_address)}}},[_c('span',{staticClass:"spinner",class:{ active: _vm.accepting }}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.accepting),expression:"!accepting"}]},[_vm._v(_vm._s(_vm.$t('views.content-detail.buttons.accept')))])])]):_vm._e()],2)}),_c('InfiniteLoading',{staticClass:"infinite-loading",attrs:{"spinner":"spiral"},on:{"infinite":_vm.infiniteHandler}})],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }