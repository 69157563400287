const languages = {
  ko: {
    name: 'ko',
    text: '한국어',
  },
  en: {
    name: 'en',
    text: 'English',
  },
}

export { languages }
