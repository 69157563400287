var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contents"},[_c('masonry',{attrs:{"cols":_vm.windowWide ? _vm.colsWide : _vm.cols}},_vm._l((_vm.contentList),function(val,i){return _c('div',{key:i,staticClass:"content"},[_c('ContentBox',{attrs:{"image":val},nativeOn:{"click":function($event){return _vm.onContentClick($event, val)}}}),_c('router-link',{staticClass:"profileBox",attrs:{"to":{
          name: 'UserOrArtist',
          params: {
            id: val.owner ? val.owner.username : val.creator.username,
            wallet_address: val.owner
              ? val.owner.wallet_address
              : val.creator.wallet_address,
          },
        }}},[_c('ContentsProfile',{attrs:{"member":val.owner || val.creator,"price":val.price}})],1)],1)}),0),_c('InfiniteLoading',{attrs:{"spinner":"spiral"},on:{"infinite":_vm.infiniteHandler}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }