export const TOGGLE_NAV = 'TOGGLE_NAV'
export const SET_SIDE_MENU_FALSE = 'SET_SIDE_MENU_FALSE'
export const SET_HEAD_NAV_TRUE = 'SET_HEAD_NAV_TRUE'
export const SET_HEAD_NAV_FALSE = 'SET_HEAD_NAV_FALSE'
export const TOGGLE_MODAL = 'TOGGLE_MODAL'
export const TOGGLE_CONFIRM_MODAL = 'TOGGLE_CONFIRM_MODAL'
export const TOGGLE_PROMPT_MODAL = 'TOGGLE_PROMPT_MODAL'
export const TOGGLE_PRIVACY_TOS_MODAL = 'TOGGLE_PRIVACY_TOS_MODAL'
export const TOGGLE_REPORT_MODAL = 'TOGGLE_REPORT_MODAL'
export const TOGGLE_NOTIFICATION_MODAL = 'TOGGLE_NOTIFICATION_MODAL'
export const TOGGLE_THEME = 'TOGGLE_THEME'
export const SET_INNER_WIDTH = 'SET_INNER_WIDTH'
export const SET_ICON_POSITION = 'SET_ICON_POSITION'
export const OPEN_TOOL_TIP = 'OPEN_TOOL_TIP'
export const CLOSE_TOOL_TIP = 'CLOSE_TOOL_TIP'
export const SET_TOOL_TIP = 'SET_TOOL_TIP'
