<template>
  <article id="ed2d0d0a-f893-4d8c-bd56-4b35bd639977" class="page sans">
    <header>
      <h1 class="page-title">개인정보처리방침</h1>
    </header>
    <div class="page-body">
      <p id="ce138acb-7ab7-4f3b-b4ed-a19b9c84f07b" class="">
        알통(이하 “회사”)은 회사가 제공하는 알통 서비스(이하 “서비스”) 이용을
        위해 "서비스”를 이용하는 회원이(이하 “이용자”)가 제공한 개인정보를 매우
        중요하게 여기고, 개인정보처리방침을 수립하여 이를 엄격히 준수합니다.
        회사의 개인정보처리방침은 관련 법령 및 정보통신부가 제정한
        개인정보보호지침을 준수하며, 회사는 개인정보가 유출되어 피해를 입지
        않도록 개인정보처리방침을 정하여 이용자의 정보보호에 최선을 다하고
        있습니다. 개인정보처리방침의 내용은 법령 및 정부지침 변경과 보다 나은
        서비스 제공을 위해서 변경될 수 있습니다. 회사는 본 개인정보처리방침을
        이용자가 언제든지 쉽게 열람하여 확인할 수 있도록 공개하고 있습니다.
      </p>
      <h3 id="87c69bd2-fa07-40a9-adc5-594435efe5f2" class="">
        <strong>제1조 개인정보 수집 · 이용 목적 및 항목</strong>
      </h3>
      <p id="454439c5-8cb9-4b8f-98cc-4642a292ad7b" class="">
        회사는 다음과 같은 목적으로 “서비스” 가입 및 제공을 위하여 필요한
        범위에서 최소한의 개인정보 만을 수집하며 수집된 개인정보는 목적 이외의
        용도로는 사용되지 않습니다. 이용 목적이 변경되거나 수집 목적 외로
        이용하는 경우 이용자로부터 별도의 동의를 받습니다. 이와 같이 수집되는
        정보는 개인정보와의 연계 여부 등에 따라 개인정보에 해당할 수 있고,
        개인정보에 해당하지 않을 수도 있습니다. “서비스”가 수집하는 개인정보
        항목과 수집 ∙ 이용 목적은 다음과 같습니다.
      </p>
      <ul id="8b5f652c-c5c2-4ca6-aa07-8a4bdd7e82bf" class="bulleted-list">
        <li style="list-style-type: disc">
          필수 수집 ∙ 이용 목적 및 항목
          <table id="ca7f6da9-4cf8-44a6-98ea-2a725df93a3d" class="simple-table">
            <tbody>
              <tr id="8ee8e10b-40d5-4b69-90ec-3f59fb94c9e6">
                <td id="_Eme" class="">수집 ∙ 이용목적</td>
                <td id="bCyL" class="">개인정보 항목</td>
              </tr>
              <tr id="4d028274-587c-4d68-88b6-e4607ceb0d91">
                <td id="_Eme" class="">
                  회원가입-가입의사 확인-분쟁 및 불만처리-법률 및 서비스
                  이용약관 위반 행위 제한
                </td>
                <td id="bCyL" class="">지갑 주소, DID</td>
              </tr>
              <tr id="f35ace07-68c0-412d-b9fb-2441f214f17d">
                <td id="_Eme" class="">
                  서비스 이용-이용자 식별-이용자 정보 관리-이용자 상담 및 각종
                  고지사항 전달
                </td>
                <td id="bCyL" class="">
                  프로필 이미지, 닉네임, 내/외국인 정보, 사용 언어
                </td>
              </tr>
              <tr id="0ff84368-a425-4893-9e26-439c016ca3cd">
                <td id="_Eme" class="">
                  NFT 거래 서비스 제공-서비스 이용 기록 제공-NFT 상품 거래정보
                  제공
                </td>
                <td id="bCyL" class="">
                  지갑 주소, DID, NFT 판매 ∙ 입찰 ∙ 구매 정보
                </td>
              </tr>
              <tr id="067d235f-34d3-4a56-ad21-b3b9eb7b1e54">
                <td id="_Eme" class="">
                  회원관리-서비스 안정성 확보-서비스 이용 기록 분석 등을 통한
                  서비스 개선
                </td>
                <td id="bCyL" class="">
                  접근 로그, IP 정보, 쿠키, 불량 및 부정 이용 기록
                </td>
              </tr>
            </tbody>
          </table>
        </li>
      </ul>
      <ul id="499eadb8-47f3-479d-a21c-f0bf6d7897cb" class="bulleted-list">
        <li style="list-style-type: disc">
          선택 수집 ∙ 이용 목적 및 항목
          <table id="f4ebeabd-7460-40a1-9124-7bc9f6373625" class="simple-table">
            <tbody>
              <tr id="328e7133-5c7c-4ead-9976-cdf686c6ff5d">
                <td id="J|&lt;&gt;" class="">수집 ∙ 이용목적</td>
                <td id="^Q[q" class="">개인정보 항목</td>
              </tr>
              <tr id="64da937a-9a31-41a5-b900-6d067c067871">
                <td id="J|&lt;&gt;" class="">
                  이벤트 및 NFT드롭 관리-이벤트 프로모션 정보 전달-마케팅 및
                  광고-NFT드롭 당첨 안내 등 알림
                </td>
                <td id="^Q[q" class="">
                  DID, 이메일, 그 외 이벤트 별로 수집 및 이용을 고지한 개인정보
                </td>
              </tr>
            </tbody>
          </table>
        </li>
      </ul>
      <ul id="15b36926-a2fa-472b-bb00-11855210abb4" class="bulleted-list">
        <li style="list-style-type: disc">
          개인정보 수집 방법“서비스”는 다음과 같은 방법으로 개인정보를
          수집합니다.
          <ul id="a68cc29a-1151-4c9e-a004-b2e298f423a4" class="bulleted-list">
            <li style="list-style-type: circle">
              서비스 이용 과정에서 이용자가 개인정보 수집에 동의하고 정보를
              제공하는 경우
            </li>
          </ul>
          <ul id="f6670d1d-e1ea-4376-84c8-2fa5b62efb23" class="bulleted-list">
            <li style="list-style-type: circle">
              회원가입이나 서비스 이용 과정에서 서비스 이용자의 자발적 제공이
              있는 경우
            </li>
          </ul>
          <ul id="d8efdfc4-80f0-41ec-a078-6b71f3be6e64" class="bulleted-list">
            <li style="list-style-type: circle">
              서비스 이용자가 고객지원 서비스를 통한 상담을 하는 경우
            </li>
          </ul>
          <ul id="27254125-7db5-498b-aa58-da6631e72ad3" class="bulleted-list">
            <li style="list-style-type: circle">
              서비스 이용자가 이벤트, 행사에 참여하는 경우
            </li>
          </ul>
          <ul id="29e08242-481d-4633-92fe-19b5ff0607cf" class="bulleted-list">
            <li style="list-style-type: circle">
              서비스 이용과정에서 이용자에 관한 정보가 자동화된 방법으로
              생성되어 수집되는 경우
            </li>
          </ul>
        </li>
      </ul>
      <h3 id="383b5f8b-1de5-48e0-8a34-79c7fbe52695" class="">
        <strong>제2조 개인정보의 보유 및 이용기간</strong>
      </h3>
      <p id="558a7e52-e6a4-48db-ad94-27d6d5c94116" class="">
        원칙적으로 개인정보 수집 및 이용 목적이 달성된 후에는 해당 정보를 바로
        파기합니다. 단, 이 개인정보처리방침에서 달리 정한 경우 또는 법령에서
        일정 기간 정보보관 의무를 부여하는 경우에는 수집한 개인정보를 해당 기간
        동안 보존합니다.
      </p>
      <ul id="afa4954e-9174-4598-8426-93ce1c11d1dc" class="bulleted-list">
        <li style="list-style-type: disc">
          이용자의 동의를 얻은 개인정보 보관기간
          <ul id="a6600382-a090-4bfc-9909-d337dc6fc44a" class="bulleted-list">
            <li style="list-style-type: circle">
              회원가입, 이용자 분쟁 및 불만 처리, 이용약관 위반 행위 제한 등
              회원 자격 관리DID, 내/외국인정보 회원 탈퇴시까지 보관
            </li>
          </ul>
          <ul id="e3503b95-3cc5-426e-976d-941d3c344d8f" class="bulleted-list">
            <li style="list-style-type: circle">
              이용자 식별, 이용자 정보 관리, 공지 사항 전달 등 서비스 제공프로필
              이미지, 닉네임, 이메일회원 탈퇴시까지 보관
            </li>
          </ul>
          <ul id="1e019d74-6aa6-4e70-b480-c10d665cb2f1" class="bulleted-list">
            <li style="list-style-type: circle">
              NFT 거래 서비스 제공이용자의 지갑 주소, DID, NFT 판매 ∙ 입찰 ∙
              구매 정보원칙적으로 영구 보관, 단 이용자의 규정 위반, 이용자의
              요청이 있는 경우 파기
            </li>
          </ul>
          <ul id="2da8f257-5020-4701-aaf9-246a9b58bac5" class="bulleted-list">
            <li style="list-style-type: circle">
              서비스 안정성 확보 및 서비스 이용 기록 ∙ 통계 분석을 위한 서비스
              개선접근 로그, IP 정보, 쿠키, 불량 ∙ 부정 이용기록부정 이용 방지를
              위하여 수집 시점으로부터 3년간 보관 후 파기부정 이용 : 법령,
              회사와 이용자 간의 서비스 이용 약관 또는 공서양속을 위반하거나
              기타 회사, 이용자 또는 타인의 권리나 이익을 침해하는 방법이나
              내용의 거래를 말합니다.
            </li>
          </ul>
          <ul id="f1c7fa6a-ec61-4588-8623-1f2d44746e6a" class="bulleted-list">
            <li style="list-style-type: circle">
              이벤트 프로모션 정보 제공 및 마케팅 ∙ 광고DID, 이메일이벤트,
              프로모션 등 수집 ∙ 이용 목적 달성시까지 보관
            </li>
          </ul>
        </li>
      </ul>
      <ul id="1ba0ee00-8f1d-4437-94a7-c2827e06bcae" class="bulleted-list">
        <li style="list-style-type: disc">
          관련 법령에 따른 개인정보 보관기간
          <table id="8f8b5d4a-070e-4c3f-808d-f13c66efb68c" class="simple-table">
            <tbody>
              <tr id="757479a1-9f71-4efa-b8b4-c585af91af52">
                <td id="gnik" class="">보존 항목</td>
                <td id="d[UL" class="">근거법령</td>
                <td id="seRU" class="">보존 기간</td>
              </tr>
              <tr id="bc033a88-3793-40f4-87a5-9679d1ad3ab9">
                <td id="gnik" class="">계약 또는 청약 철회 등에 관한 기록</td>
                <td id="d[UL" class="">
                  전자상거래 등에서의 소비자보호에 관련한 법률
                </td>
                <td id="seRU" class="">5년</td>
              </tr>
              <tr id="faa0b941-6fb5-46d1-afff-dd4c57d386b7">
                <td id="gnik" class="">
                  대금 결제 및 재화 등의 공급에 관한 기록
                </td>
                <td id="d[UL" class="">5년</td>
                <td id="seRU" class=""></td>
              </tr>
              <tr id="5b08735e-7fa9-47a9-90fd-91c3c00d053b">
                <td id="gnik" class="">
                  소비자의 불만 또는 또는 분쟁처리에 관한 기록
                </td>
                <td id="d[UL" class="">3년</td>
                <td id="seRU" class=""></td>
              </tr>
              <tr id="31d7cea1-e6f7-4acf-9d34-ec76f814911c">
                <td id="gnik" class="">표시·광고에 관한 기록</td>
                <td id="d[UL" class="">6개월</td>
                <td id="seRU" class=""></td>
              </tr>
              <tr id="549d5703-ab87-4fa6-9317-c1bc19786018">
                <td id="gnik" class="">보상 및 오입금에 관한 기록</td>
                <td id="d[UL" class="">5년</td>
                <td id="seRU" class=""></td>
              </tr>
              <tr id="c5e6cd7e-2e83-44db-8c98-c442e07c132c">
                <td id="gnik" class="">로그인 기록</td>
                <td id="d[UL" class="">통신비밀보호법</td>
                <td id="seRU" class="">3개월</td>
              </tr>
              <tr id="5a0a21bb-6932-4bd5-9c72-f0f56a922dad">
                <td id="gnik" class="">사이트 방문에 관한 기록</td>
                <td id="d[UL" class="">3개월</td>
                <td id="seRU" class=""></td>
              </tr>
              <tr id="06cd2ed0-b2a6-48b0-ae26-9460e9d7edc3">
                <td id="gnik" class="">전자금융거래에 관한 기록</td>
                <td id="d[UL" class="">전자금융거래법</td>
                <td id="seRU" class="">5년</td>
              </tr>
              <tr id="82a2a0e0-4d8e-487f-a42c-b474367756f3">
                <td id="gnik" class="">본인 확인에 관한 기록</td>
                <td id="d[UL" class="">
                  정보통신망 이용촉진 및 정보보호 등에 관한 법률
                </td>
                <td id="seRU" class="">6개월</td>
              </tr>
            </tbody>
          </table>
        </li>
      </ul>
      <ul id="45340a20-5fb7-4ac9-8651-c1a5385b67ad" class="bulleted-list">
        <li style="list-style-type: disc">
          회사는 법령 및 ‘개인정보 유효 기간제’에 따라 1 년간 서비스를 이용하지
          않는 장기 서비스 미이용자의 개인정보를 사전 통지 후 분리 보관하고 5년
          후 파기하고 있습니다.
        </li>
      </ul>
      <h3 id="6c8fff29-176e-49fd-ac63-fbac3d4c0cd7" class="">
        <strong>제3조 개인정보처리 제3자 제공</strong>
      </h3>
      <p id="a8aebd91-4957-4930-948f-a366543d5b8d" class="">
        회사는 개인정보를 처리 목적으로 명시한 범위를 초과하여 제3자에게
        제공하지 않습니다. 다만, 다음 각 호의 경우에는 제3자에게 제공할 수
        있습니다.
      </p>
      <ul id="d323ac7a-cd11-47c7-bab9-b435d79bd0f5" class="bulleted-list">
        <li style="list-style-type: disc">이용자로부터 동의를 받은 경우</li>
      </ul>
      <ul id="30928107-32e5-438d-b5ab-8ea3900742b3" class="bulleted-list">
        <li style="list-style-type: disc">
          다른 법률에 특별한 규정이 있는 경우
        </li>
      </ul>
      <ul id="54aecfa6-e921-4738-a37a-c0132f81ba72" class="bulleted-list">
        <li style="list-style-type: disc">
          수사·조사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관 및 감독
          당국의 요구가 있는 경우
        </li>
      </ul>
      <h3 id="ea3187de-538b-4755-9e32-d013c6064170" class="">
        <strong>제4조 개인정보처리 제3자 위탁</strong>
      </h3>
      <p id="67ee28bf-693b-4ce2-afa4-0ca72828fd14" class="">
        회사는 이용자의 사전 동의 없이 개인정보를 외부에 제공하지 않습니다. 단
        회사는 보다 나은 서비스 제공과 이용자의 편의 제공 등 업무수행을 원활하게
        하기 위해 이용자의 개인정보를 동의 받은 이용목적 내에서 다음과 같이 타
        업체에 수집, 보관, 취급 등을 위탁하고 있습니다. 이 경우 관련 법령에 따라
        이용자에게 개인정보 제3자 제공에 관하여 사전에 고지하고 동의를 얻습니다.
        이용자가 동의하지 않은 경우 제휴사 등에게 개인정보를 제공 · 공유하지
        않으며 동의 후에도 정보제공 동의를 철회할 수 있습니다. 위탁업무의
        내용이나 수탁자가 변경될 경우 지체없이 본 개인정보 처리방침을 통하여
        공개합니다.
      </p>
      <ul id="d49a8159-5211-4933-8090-a16ddd6b6931" class="bulleted-list">
        <li style="list-style-type: disc">
          개인정보 위탁업무 내용관계 법령의 규정에 의해 보존의 필요가 있는
          경우는 해당 기간까지 이용 및 보관합니다.
          <table id="f4f809e2-5fa9-42e3-a84a-f1ccbf54dd58" class="simple-table">
            <tbody>
              <tr id="9bb4cd7c-7116-4f81-abb3-f7bceea36578">
                <td id="LtvQ" class="">수탁업체</td>
                <td id="rl&gt;B" class="">개인정보 제공 목적</td>
                <td id="@DXE" class="">이용 기간</td>
                <td id="BN`P" class="">제공하는 개인정보</td>
              </tr>
              <tr id="48927467-2931-4854-b20f-d42bfae6bf2b">
                <td id="LtvQ" class="">MailChimp(미국)</td>
                <td id="rl&gt;B" class="">이메일 발송</td>
                <td id="@DXE" class="">
                  이용목적 달성 또는 위탁계약 종료 시까지
                </td>
                <td id="BN`P" class="">
                  이름, 이메일 주소, NFT 정보, 디지털 자산 정보
                </td>
              </tr>
              <tr id="fb65b2ae-c0b2-4b62-8869-5fefcc1ec4b0">
                <td id="LtvQ" class="">Amazon Web Services, Inc</td>
                <td id="rl&gt;B" class="">데이터 보관 및 인프라 관리</td>
                <td id="@DXE" class="">
                  이용목적 달성 또는 위탁계약 종료 시까지
                </td>
                <td id="BN`P" class="">
                  서비스 이용 중 수집되는 개인정보 및 로그정보
                </td>
              </tr>
            </tbody>
          </table>
        </li>
      </ul>
      <ul id="a1c9c480-4e7e-4d47-931a-8edf8f19583e" class="bulleted-list">
        <li style="list-style-type: disc">
          개인정보 국외 법인 위탁 처리원활한 서비스 제공 및 이용자 편의 증진
          등을 위하여 해외 클라우드 서비스 등을 이용하고 있습니다. 이와 관련하여
          개인정보 국외이전에 대해 다음과 같이 알려드립니다.
          <table id="785ee853-ad77-47bd-ac90-fcc25ebf4bad" class="simple-table">
            <tbody>
              <tr id="c2df6b4a-b901-4380-82ca-ed4974bd29c3">
                <td id="D~og" class="">이전업체</td>
                <td id="PYQb" class="">연락처</td>
                <td id="\:xm" class="">이전목적</td>
                <td id="Zznr" class="">이전 항목</td>
                <td id="TIJj" class="">이전 국가</td>
                <td id="E?k[" class="">일시 및 방법</td>
                <td id="VuXN" class="">개인정보 이용기간</td>
              </tr>
              <tr id="cb0b8957-47e8-4bc8-9371-8d5f8e2733b9">
                <td id="D~og" class="">MailChimp</td>
                <td id="PYQb" class="">personaldatarequests@mailchimp.com</td>
                <td id="\:xm" class="">이메일 발송 서비스</td>
                <td id="Zznr" class="">
                  이름, 이메일 주소, NFT 정보, 디지털 자산 정보
                </td>
                <td id="TIJj" class="">미국</td>
                <td id="E?k[" class="">
                  서비스 이용시마다 네트워크를 통해 전송
                </td>
                <td id="VuXN" class="">
                  회원탈퇴 시 혹은 위탁 계약 종료 시 까지
                </td>
              </tr>
            </tbody>
          </table>
        </li>
      </ul>
      <h3 id="0bf377d7-9d1b-4b3a-8391-0996f7bc9699" class="">
        <strong>제5조 개인정보의 파기</strong>
      </h3>
      <ul id="35f6a526-4e13-48e0-8c57-222cd690a61a" class="bulleted-list">
        <li style="list-style-type: disc">
          회사는 개인정보의 보유 및 이용 기간의 경과, 처리 목적 달성 등
          개인정보가 불필요하게 되었을 때는 지체없이 해당 개인정보를 파기하며
          개인정보 파기절차 및 방법은 다음과 같습니다.
          <ul id="e37932ff-473e-4120-98d6-e63e03545c9e" class="bulleted-list">
            <li style="list-style-type: circle">
              전자적 파일: 복원이 불가능한 방법으로 영구 삭제
            </li>
          </ul>
          <ul id="8ac4bd71-f237-4dc6-9d85-eb6d804889bc" class="bulleted-list">
            <li style="list-style-type: circle">
              인쇄물, 서면, 그 밖의 기록매체: 파쇄 또는 소각
            </li>
          </ul>
        </li>
      </ul>
      <ul id="144377c6-1566-4742-b04f-06919fff6f48" class="bulleted-list">
        <li style="list-style-type: disc">
          이용자로부터 동의 받은 개인정보 보유 및 이용 기간이 지나거나 처리
          목적이 달성되었음에도 불구하고 법령에 따라 개인정보를 계속 보존하여야
          하는 경우에는, 해당 개인 정보를 별도의 데이터베이스(DB) 로 옮기거나
          보관장소를 달리하여 보존합니다.
        </li>
      </ul>
      <h3 id="9bf200e1-d634-48e2-9178-041edb39a14c" class="">
        <strong>제6조 개인정보의 보호를 위한 기술적·관리적 대책</strong>
      </h3>
      <p id="6ef72014-089c-4d67-ba1e-2d1683383613" class="">
        회사는 이용자의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출,
        변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적
        대책을 강구하고 있습니다.
      </p>
      <ul id="e17875b1-5a38-4601-97c7-1836592aae48" class="bulleted-list">
        <li style="list-style-type: disc">
          기술적 조치회사는 사용자의 개인정보를 취급함에 있어 개인정보가 분실,
          도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은
          조치를 취하고 있습니다.
          <ul id="9094d9e5-bce3-45b6-89ee-c030e1dbaf96" class="bulleted-list">
            <li style="list-style-type: circle">
              개인정보처리시스템 등의 접근권한 관리 및 접근통제시스템 설치
            </li>
          </ul>
          <ul id="63017c4b-be46-4948-bedd-ecf30097689d" class="bulleted-list">
            <li style="list-style-type: circle">개인정보의 암호화</li>
          </ul>
          <ul id="b14436e7-1881-47d3-a3ed-8d00fcaa7cd4" class="bulleted-list">
            <li style="list-style-type: circle">
              해킹 등 외부 침입에 대비한 침입차단 시스템 설치
            </li>
          </ul>
        </li>
      </ul>
      <ul id="25d9bdad-e965-495d-b250-e070b7ce8f3c" class="bulleted-list">
        <li style="list-style-type: disc">
          관리적 조치회사는 사용자의 개인정보에 대한 접근권한을 최소한의
          인원으로 제한하고 있습니다. 그 최소한의 인원에 해당하는 자는 다음과
          같습니다. 개인정보를 취급하는 직원을 대상으로는 새로운 보안 기술 습득
          및 개인정보 보호 의무 등에 관해 정기적인 사내 교육 및 외부 위탁교육을
          실시하고 있습니다.
          <ul id="092171f3-f20b-4e07-a1b8-23cc2f7be529" class="bulleted-list">
            <li style="list-style-type: circle">
              이용자를 직접 상대로 하여 마케팅 업무를 수행하는 경우
            </li>
          </ul>
          <ul id="c92c3477-1f73-4e63-88d1-1be2519406e5" class="bulleted-list">
            <li style="list-style-type: circle">
              개인정보관리책임자 및 담당자 등 개인정보관리업무를 수행하는 경우
            </li>
          </ul>
          <ul id="ace31cc1-cadb-4c0c-8502-783c46703010" class="bulleted-list">
            <li style="list-style-type: circle">
              기타 업무상 개인정보의 취급이 불가피한 경우
            </li>
          </ul>
        </li>
      </ul>
      <h3 id="f11d139c-6c75-42e3-9c9a-68bf96dfbcd1" class="">
        <strong>제7조 이용자의 권리·의무 및 행사 방법</strong>
      </h3>
      <ul id="978a3e69-e3d1-4397-a46b-fd9bb46d781b" class="bulleted-list">
        <li style="list-style-type: disc">
          이용자는 회사에 대해 언제든지 개인정보 열람・정정・삭제・처리 정지
          요구 등의 권리를 행사할 수 있습니다. 다만, 개인정보보호법 등 관계
          법령에서 정하는 바에 따라 이용자의 개인정보 열람・정정・삭제・처리
          정지 요구 등의 권리 행사가 제한될 수 있습니다.
        </li>
      </ul>
      <ul id="c6cf8d44-8cc8-4bf1-8f5c-84b2a4cf1f62" class="bulleted-list">
        <li style="list-style-type: disc">
          이용자의 권리 행사는 전자우편 등을 통하여 하실 수 있으며, 회사는 이에
          대해 지체없이 조치하겠습니다.
        </li>
      </ul>
      <ul id="628557f5-ce28-4e28-a845-529a5487c74a" class="bulleted-list">
        <li style="list-style-type: disc">
          개인정보의 정정 및 삭제 요구 시 다른 법령에서 그 개인정보가 수집
          대상으로 명시되어 있는 경우에는 이용자의 요구에도 불구하고 당해 정보가
          삭제되지 않을 수 있습니다.
        </li>
      </ul>
      <ul id="5d962707-60e2-4603-a459-a3bc13b4ca04" class="bulleted-list">
        <li style="list-style-type: disc">
          회사는 이용자 이용 권리에 따른 열람의 요구, 정정・삭제의 요구, 처리
          정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를
          확인합니다. 본인 및 정당한 대리인 여부가 확인되지 않는 경우 회사는
          정보 처리에 관한 요구에 불응할 수 있습니다.
        </li>
      </ul>
      <ul id="72d5778e-9be4-4f85-a35b-bdfa4c122865" class="bulleted-list">
        <li style="list-style-type: disc">
          이용자는 권리행사의 거부 등에 대하여 불복 시 회사에 이의제기를 하실 수
          있으며, 회사는 이에 대해 지체없이 조치하겠습니다.
        </li>
      </ul>
      <h3 id="1f7e1bad-f374-4621-bc30-3b320d437ce5" class="">
        <strong
          >제8조 개인정보 자동수집 장치의 설치.운영 및 그 거부에 관한
          사항</strong
        >
      </h3>
      <p id="875d5a2d-f4e5-4702-94a3-48cb9c5ba198" class="">
        회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용 정보를
        저장하고 수시로 불러오는 쿠키(cookie)’를 사용합니다. 쿠키는 웹사이트가
        고객의 컴퓨터 브라우저(인터넷 익스플로러 등)에 전송하는 소량의
        정보입니다.
      </p>
      <ul id="447f40bd-8884-4818-9a1e-1c2c506f17b0" class="bulleted-list">
        <li style="list-style-type: disc">
          쿠키의 사용 목적쿠키를 통해 이용자가 선호하는 설정 등을 저장하여
          이용자에게 더욱 빠른 웹 환경을 지원하며, 편리한 이용을 위해 서비스
          개선에 활용합니다. 이를 통해 이용자는 더욱 손쉽게 서비스를 이용할 수
          있게 됩니다.
        </li>
      </ul>
      <ul id="88ee5780-4049-427a-b4ed-ac7e47b70f5c" class="bulleted-list">
        <li style="list-style-type: disc">
          쿠키의 설치.운영 및 거부이용자는 쿠키 설치에 대한 선택권을 가지고
          있으며, 아래의 방법을 통해 언제든지 이러한 쿠키의 저장을 거부하거나
          삭제할 수 있습니다.
        </li>
      </ul>
      <ul id="492bf340-01ad-40bc-84a0-589d7c59f100" class="bulleted-list">
        <li style="list-style-type: disc">
          쿠키 설정 거부 방법단, 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에
          어려움이 발생할 수 있습니다.
          <ul id="9b3f4721-fb3f-476f-8321-ae0b553289b6" class="bulleted-list">
            <li style="list-style-type: circle">
              Internet Explorer : 도구 메뉴 선택 &gt; 인터넷 옵션 선택 &gt;
              개인정보 탭 클릭 &gt; 고급 개인정보 설정 &gt; 쿠키 수준 설정
            </li>
          </ul>
          <ul id="88e36ac3-9acd-4701-afba-8c4c4c5ca86a" class="bulleted-list">
            <li style="list-style-type: circle">
              Chrome : 설정 메뉴 선택 &gt; 고급 설정 표시 선택 &gt; 개인정보 및
              보안 &gt; 콘텐츠 설정 선택 &gt; 쿠키 수준 설정
            </li>
          </ul>
          <ul id="eae2df3f-701e-46c4-b954-82d28c4c4022" class="bulleted-list">
            <li style="list-style-type: circle">
              Safari : 환경설정 메뉴 선택 &gt; 개인정보 탭 선택 &gt; 쿠키 및 웹
              사이트 데이터 수준 설정
            </li>
          </ul>
          <ul id="ed5ab888-8178-4025-aba0-665a868b775a" class="bulleted-list">
            <li style="list-style-type: circle">
              FireFox: 도구 메뉴 선택 &gt; 환경 설정 메뉴 선택 &gt; 개인 정보 및
              보안 탭 선택 &gt; 콘텐츠 차단 설정
            </li>
          </ul>
          <ul id="52811d2f-aed9-4f5f-ac8b-b26c26c8da8d" class="bulleted-list">
            <li style="list-style-type: circle">
              Opera: 도구 메뉴 선택 &gt; 설정 메뉴 선택 &gt; 개인 정보 보호 및
              보안 탭 선택 &gt; 쿠키 수준 설정
            </li>
          </ul>
        </li>
      </ul>
      <h3 id="3b28bbd6-61c7-48e6-b8c4-a8b91ae08fcb" class="">
        <strong>제9조 개인정보 보호 책임자 및 담당 부서</strong>
      </h3>
      <ul id="a38482b0-cfb9-45e3-8102-48d182af7de2" class="bulleted-list">
        <li style="list-style-type: disc">
          회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기
          위하여 아래와 같이 관련 부서 및 개인정보 보호 책임자를 지정하고
          있습니다.이름: 전태호 소속: 운영팀 연락처: 010-6356-3085 이메일:
          admin@artong.io
        </li>
      </ul>
      <ul id="5a0e6b77-d6eb-4b36-a2cb-d6912152cd08" class="bulleted-list">
        <li style="list-style-type: disc">
          이용자가 회사의 서비스를 이용하며 발생하는 모든 개인정보보호 관련
          민원은 개인정보 보호 책임자 및 담당 부서로 문의하실 수 있습니다.
          회사는 이용자의 문의 사항에 대해 충분한 답변을 드릴 수 있도록 최선을
          다하겠습니다
        </li>
      </ul>
      <h3 id="80579390-02ea-4f8e-b4f3-ad35eef05944" class="">
        <strong>제10조 권익침해 구제방법</strong>
      </h3>
      <p id="eb8e7809-a1b4-46b6-a9e8-2c262d692d1e" class="">
        개인정보 침해에 대한 피해구제, 상담 등이 필요할 경우 다음 기관에
        문의하실 수 있습니다.
      </p>
      <p id="b2c03f77-77ee-45df-87b4-3db8de34e44b" class="">
        개인정보 침해신고센터 (한국인터넷진흥원 운영)
      </p>
      <ul id="07af6d97-5a77-4883-8e69-4edb403cf48c" class="bulleted-list">
        <li style="list-style-type: disc">홈페이지 : privacy.kisa.or.kr</li>
      </ul>
      <ul id="8f1d4158-0235-4de2-bf39-ea19b350a0df" class="bulleted-list">
        <li style="list-style-type: disc">전화 : (국번없이) 118</li>
      </ul>
      <p id="0439877d-dfb6-4a46-87a3-05abe1ce5926" class="">
        개인정보 분쟁조정위원회
      </p>
      <ul id="9d6cf56d-2801-457d-b667-51fe5e2eb9cb" class="bulleted-list">
        <li style="list-style-type: disc">홈페이지 : www.kopico.go.kr</li>
      </ul>
      <ul id="0f6bd64c-01a3-49b4-83f0-4ee8624a5fa7" class="bulleted-list">
        <li style="list-style-type: disc">전화 : (국번없이) 1833-6972</li>
      </ul>
      <p id="101614ff-4890-4c87-8f89-3b0c875e822f" class="">
        대검찰청 사이버범죄수사단
      </p>
      <ul id="9b1274f7-c97b-40ec-aa2e-1fb03cb5a914" class="bulleted-list">
        <li style="list-style-type: disc">홈페이지 : www.spo.go.kr</li>
      </ul>
      <ul id="0fdae048-2982-4abb-8a00-b9bd6267938a" class="bulleted-list">
        <li style="list-style-type: disc">전화 : 02-3480-3573</li>
      </ul>
      <p id="eb94f4d4-e826-41a7-bb35-0ef5bc4d41d7" class="">
        경찰청 사이버안전국
      </p>
      <ul id="b335277a-a029-4a7c-8007-6a3af7c06fbc" class="bulleted-list">
        <li style="list-style-type: disc">
          홈페이지 : cyberbureau.police.go.kr
        </li>
      </ul>
      <ul id="e3bdc5b1-bda9-43f3-9fde-e1dbfba1c8ec" class="bulleted-list">
        <li style="list-style-type: disc">전화 : (국번없이) 182</li>
      </ul>
      <h3 id="3068b4ee-0a84-4534-b6c9-4f3b4df006a8" class="">
        <strong>제11조 링크 사이트에 대한 책임</strong>
      </h3>
      <p id="fa1b0593-903d-4659-88bf-08320c01b44b" class="">
        회사는 이용자에게 다른 외부사이트로 연결되는 링크를 제공할 수 있습니다.
        이 경우 회사는 외부사이트에 대한 통제권이 없으므로 이용자가
        외부사이트로부터 제공받은 서비스나 자료의 유용성, 진실성, 적법성에 대해
        책임 및 보증할 수 없으며, 링크된 외부사이트의 개인정보처리방침은 회사와
        무관하므로 해당 외부사이트의 정책을 확인하시기 바랍니다.
      </p>
      <h3 id="fc262a3d-80eb-4b8e-bf0c-e4ad8c6b33d4" class="">
        <strong>제12조 개인정보처리방침 변경</strong>
      </h3>
      <p id="a86da227-b07c-4702-b85c-549c4fb18ef6" class="">
        이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른
        변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 15일전부터
        회사 사이트의 공지사항(또는 이메일 등 개별공지)을 통하여 고지할
        예정입니다. 다만, 개인정보의 수집 및 활용, 제 3 자 제공 등과 같이 이용자
        권리의 중요한 변경이 있을 때는 최소 30 일 이전에 알립니다.
      </p>
      <p id="212a80d4-dba4-4692-b354-f3323b319a8e" class="">
        공고일자 : 2023년 02월 28일
      </p>
      <p id="216cadd6-f500-4461-bacf-59d34febb951" class="">
        시행일자 : 2023년 02월 28일
      </p>
    </div>
  </article>
</template>

<script>
export default {
  name: 'Privacy',
}
</script>

<style scoped>
html,
body {
  margin: 0;
  padding: 0;
}
@media only screen {
  body {
    margin: 2em auto;
    max-width: 900px;
    color: rgb(55, 53, 47);
  }
}
p {
  line-height: 1.8;
}
body {
  line-height: 1.5;
  white-space: pre-wrap;
}

a,
a.visited {
  color: inherit;
  text-decoration: underline;
}

.pdf-relative-link-path {
  font-size: 80%;
  color: #444;
}

h1,
h2,
h3 {
  letter-spacing: -0.01em;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 0;
}

.page-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0.75em;
}

h1 {
  font-size: 1.875rem;
  margin-top: 1.875rem;
}

h2 {
  font-size: 1.5rem;
  margin-top: 1.5rem;
}

h3 {
  font-size: 1.25rem;
  margin-top: 1.25rem;
}

.source {
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 1.5em;
  word-break: break-all;
}

.callout {
  border-radius: 3px;
  padding: 1rem;
}

figure {
  margin: 1.25em 0;
  page-break-inside: avoid;
}

figcaption {
  opacity: 0.5;
  font-size: 85%;
  margin-top: 0.5em;
}

mark {
  background-color: transparent;
}

.indented {
  padding-left: 1.5em;
}

hr {
  background: transparent;
  display: block;
  width: 100%;
  height: 1px;
  visibility: visible;
  border: none;
  border-bottom: 1px solid rgba(55, 53, 47, 0.09);
}

img {
  max-width: 100%;
}

@media only print {
  img {
    max-height: 100vh;
    object-fit: contain;
  }
}

@page {
  margin: 1in;
}

.collection-content {
  font-size: 0.875rem;
}

.column-list {
  display: flex;
  justify-content: space-between;
}

.column {
  padding: 0 1em;
}

.column:first-child {
  padding-left: 0;
}

.column:last-child {
  padding-right: 0;
}

.table_of_contents-item {
  display: block;
  font-size: 0.875rem;
  line-height: 1.3;
  padding: 0.125rem;
}

.table_of_contents-indent-1 {
  margin-left: 1.5rem;
}

.table_of_contents-indent-2 {
  margin-left: 3rem;
}

.table_of_contents-indent-3 {
  margin-left: 4.5rem;
}

.table_of_contents-link {
  text-decoration: none;
  opacity: 0.7;
  border-bottom: 1px solid rgba(55, 53, 47, 0.18);
}

table,
th,
td {
  border: 1px solid rgba(55, 53, 47, 0.09);
  border-collapse: collapse;
}

table {
  border-left: none;
  border-right: none;
}

th,
td {
  font-weight: normal;
  padding: 0.25em 0.5em;
  line-height: 1.5;
  min-height: 1.5em;
  text-align: left;
}

th {
  color: rgba(55, 53, 47, 0.6);
}

ol,
ul {
  margin: 0;
  margin-block-start: 0.6em;
  margin-block-end: 0.6em;
}

li > ol:first-child,
li > ul:first-child {
  margin-block-start: 0.6em;
}

ul > li {
  list-style: disc;
}

ul.to-do-list {
  padding-inline-start: 0;
}

ul.to-do-list > li {
  list-style: none;
}

.to-do-children-checked {
  text-decoration: line-through;
  opacity: 0.375;
}

ul.toggle > li {
  list-style: none;
}

ul {
  padding-inline-start: 1.7em;
}

ul > li {
  padding-left: 0.1em;
}

ol {
  padding-inline-start: 1.6em;
}

ol > li {
  padding-left: 0.2em;
}

.mono ol {
  padding-inline-start: 2em;
}

.mono ol > li {
  text-indent: -0.4em;
}

.toggle {
  padding-inline-start: 0em;
  list-style-type: none;
}

/* Indent toggle children */
.toggle > li > details {
  padding-left: 1.7em;
}

.toggle > li > details > summary {
  margin-left: -1.1em;
}

.selected-value {
  display: inline-block;
  padding: 0 0.5em;
  background: rgba(206, 205, 202, 0.5);
  border-radius: 3px;
  margin-right: 0.5em;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  white-space: nowrap;
}

.collection-title {
  display: inline-block;
  margin-right: 1em;
}

.simple-table {
  margin-top: 1em;
  font-size: 0.875rem;
  empty-cells: show;
}
.simple-table td {
  height: 29px;
  min-width: 120px;
}

.simple-table th {
  height: 29px;
  min-width: 120px;
}

.simple-table-header-color {
  background: rgb(247, 246, 243);
  color: black;
}
.simple-table-header {
  font-weight: 500;
}

time {
  opacity: 0.5;
}

.icon {
  display: inline-block;
  max-width: 1.2em;
  max-height: 1.2em;
  text-decoration: none;
  vertical-align: text-bottom;
  margin-right: 0.5em;
}

img.icon {
  border-radius: 3px;
}

.user-icon {
  width: 1.5em;
  height: 1.5em;
  border-radius: 100%;
  margin-right: 0.5rem;
}

.user-icon-inner {
  font-size: 0.8em;
}

.text-icon {
  border: 1px solid #000;
  text-align: center;
}

.page-cover-image {
  display: block;
  object-fit: cover;
  width: 100%;
  max-height: 30vh;
}

.page-header-icon {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.page-header-icon-with-cover {
  margin-top: -0.72em;
  margin-left: 0.07em;
}

.page-header-icon img {
  border-radius: 3px;
}

.link-to-page {
  margin: 1em 0;
  padding: 0;
  border: none;
  font-weight: 500;
}

p > .user {
  opacity: 0.5;
}

td > .user,
td > time {
  white-space: nowrap;
}

input[type='checkbox'] {
  transform: scale(1.5);
  margin-right: 0.6em;
  vertical-align: middle;
}

p {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.image {
  border: none;
  margin: 1.5em 0;
  padding: 0;
  border-radius: 0;
  text-align: center;
}

.code,
code {
  background: rgba(135, 131, 120, 0.15);
  border-radius: 3px;
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-size: 85%;
  tab-size: 2;
}

code {
  color: #eb5757;
}

.code {
  padding: 1.5em 1em;
}

.code-wrap {
  white-space: pre-wrap;
  word-break: break-all;
}

.code > code {
  background: none;
  padding: 0;
  font-size: 100%;
  color: inherit;
}

blockquote {
  font-size: 1.25em;
  margin: 1em 0;
  padding-left: 1em;
  border-left: 3px solid rgb(55, 53, 47);
}

.bookmark {
  text-decoration: none;
  max-height: 8em;
  padding: 0;
  display: flex;
  width: 100%;
  align-items: stretch;
}

.bookmark-title {
  font-size: 0.85em;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 1.75em;
  white-space: nowrap;
}

.bookmark-text {
  display: flex;
  flex-direction: column;
}

.bookmark-info {
  flex: 4 1 180px;
  padding: 12px 14px 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bookmark-image {
  width: 33%;
  flex: 1 1 180px;
  display: block;
  position: relative;
  object-fit: cover;
  border-radius: 1px;
}

.bookmark-description {
  color: rgba(55, 53, 47, 0.6);
  font-size: 0.75em;
  overflow: hidden;
  max-height: 4.5em;
  word-break: break-word;
}

.bookmark-href {
  font-size: 0.75em;
  margin-top: 0.25em;
}

.sans {
  font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Helvetica, 'Apple Color Emoji', Arial, sans-serif, 'Segoe UI Emoji',
    'Segoe UI Symbol';
}
.code {
  font-family: 'SFMono-Regular', Menlo, Consolas, 'PT Mono', 'Liberation Mono',
    Courier, monospace;
}
.serif {
  font-family: Lyon-Text, Georgia, ui-serif, serif;
}
.mono {
  font-family: iawriter-mono, Nitti, Menlo, Courier, monospace;
}
.pdf .sans {
  font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Helvetica, 'Apple Color Emoji', Arial, sans-serif,
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Twemoji', 'Noto Color Emoji',
    'Noto Sans CJK JP';
}
.pdf:lang(zh-CN) .sans {
  font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Helvetica, 'Apple Color Emoji', Arial, sans-serif,
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Twemoji', 'Noto Color Emoji',
    'Noto Sans CJK SC';
}
.pdf:lang(zh-TW) .sans {
  font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Helvetica, 'Apple Color Emoji', Arial, sans-serif,
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Twemoji', 'Noto Color Emoji',
    'Noto Sans CJK TC';
}
.pdf:lang(ko-KR) .sans {
  font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Helvetica, 'Apple Color Emoji', Arial, sans-serif,
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Twemoji', 'Noto Color Emoji',
    'Noto Sans CJK KR';
}
.pdf .code {
  font-family: Source Code Pro, 'SFMono-Regular', Menlo, Consolas, 'PT Mono',
    'Liberation Mono', Courier, monospace, 'Twemoji', 'Noto Color Emoji',
    'Noto Sans Mono CJK JP';
}
.pdf:lang(zh-CN) .code {
  font-family: Source Code Pro, 'SFMono-Regular', Menlo, Consolas, 'PT Mono',
    'Liberation Mono', Courier, monospace, 'Twemoji', 'Noto Color Emoji',
    'Noto Sans Mono CJK SC';
}
.pdf:lang(zh-TW) .code {
  font-family: Source Code Pro, 'SFMono-Regular', Menlo, Consolas, 'PT Mono',
    'Liberation Mono', Courier, monospace, 'Twemoji', 'Noto Color Emoji',
    'Noto Sans Mono CJK TC';
}
.pdf:lang(ko-KR) .code {
  font-family: Source Code Pro, 'SFMono-Regular', Menlo, Consolas, 'PT Mono',
    'Liberation Mono', Courier, monospace, 'Twemoji', 'Noto Color Emoji',
    'Noto Sans Mono CJK KR';
}
.pdf .serif {
  font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, 'Twemoji',
    'Noto Color Emoji', 'Noto Serif CJK JP';
}
.pdf:lang(zh-CN) .serif {
  font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, 'Twemoji',
    'Noto Color Emoji', 'Noto Serif CJK SC';
}
.pdf:lang(zh-TW) .serif {
  font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, 'Twemoji',
    'Noto Color Emoji', 'Noto Serif CJK TC';
}
.pdf:lang(ko-KR) .serif {
  font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, 'Twemoji',
    'Noto Color Emoji', 'Noto Serif CJK KR';
}
.pdf .mono {
  font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace,
    'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK JP';
}
.pdf:lang(zh-CN) .mono {
  font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace,
    'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK SC';
}
.pdf:lang(zh-TW) .mono {
  font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace,
    'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK TC';
}
.pdf:lang(ko-KR) .mono {
  font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace,
    'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK KR';
}
.highlight-default {
  color: rgba(55, 53, 47, 1);
}
.highlight-gray {
  color: rgba(120, 119, 116, 1);
  fill: rgba(120, 119, 116, 1);
}
.highlight-brown {
  color: rgba(159, 107, 83, 1);
  fill: rgba(159, 107, 83, 1);
}
.highlight-orange {
  color: rgba(217, 115, 13, 1);
  fill: rgba(217, 115, 13, 1);
}
.highlight-yellow {
  color: rgba(203, 145, 47, 1);
  fill: rgba(203, 145, 47, 1);
}
.highlight-teal {
  color: rgba(68, 131, 97, 1);
  fill: rgba(68, 131, 97, 1);
}
.highlight-blue {
  color: rgba(51, 126, 169, 1);
  fill: rgba(51, 126, 169, 1);
}
.highlight-purple {
  color: rgba(144, 101, 176, 1);
  fill: rgba(144, 101, 176, 1);
}
.highlight-pink {
  color: rgba(193, 76, 138, 1);
  fill: rgba(193, 76, 138, 1);
}
.highlight-red {
  color: rgba(212, 76, 71, 1);
  fill: rgba(212, 76, 71, 1);
}
.highlight-gray_background {
  background: rgba(241, 241, 239, 1);
}
.highlight-brown_background {
  background: rgba(244, 238, 238, 1);
}
.highlight-orange_background {
  background: rgba(251, 236, 221, 1);
}
.highlight-yellow_background {
  background: rgba(251, 243, 219, 1);
}
.highlight-teal_background {
  background: rgba(237, 243, 236, 1);
}
.highlight-blue_background {
  background: rgba(231, 243, 248, 1);
}
.highlight-purple_background {
  background: rgba(244, 240, 247, 0.8);
}
.highlight-pink_background {
  background: rgba(249, 238, 243, 0.8);
}
.highlight-red_background {
  background: rgba(253, 235, 236, 1);
}
.block-color-default {
  color: inherit;
  fill: inherit;
}
.block-color-gray {
  color: rgba(120, 119, 116, 1);
  fill: rgba(120, 119, 116, 1);
}
.block-color-brown {
  color: rgba(159, 107, 83, 1);
  fill: rgba(159, 107, 83, 1);
}
.block-color-orange {
  color: rgba(217, 115, 13, 1);
  fill: rgba(217, 115, 13, 1);
}
.block-color-yellow {
  color: rgba(203, 145, 47, 1);
  fill: rgba(203, 145, 47, 1);
}
.block-color-teal {
  color: rgba(68, 131, 97, 1);
  fill: rgba(68, 131, 97, 1);
}
.block-color-blue {
  color: rgba(51, 126, 169, 1);
  fill: rgba(51, 126, 169, 1);
}
.block-color-purple {
  color: rgba(144, 101, 176, 1);
  fill: rgba(144, 101, 176, 1);
}
.block-color-pink {
  color: rgba(193, 76, 138, 1);
  fill: rgba(193, 76, 138, 1);
}
.block-color-red {
  color: rgba(212, 76, 71, 1);
  fill: rgba(212, 76, 71, 1);
}
.block-color-gray_background {
  background: rgba(241, 241, 239, 1);
}
.block-color-brown_background {
  background: rgba(244, 238, 238, 1);
}
.block-color-orange_background {
  background: rgba(251, 236, 221, 1);
}
.block-color-yellow_background {
  background: rgba(251, 243, 219, 1);
}
.block-color-teal_background {
  background: rgba(237, 243, 236, 1);
}
.block-color-blue_background {
  background: rgba(231, 243, 248, 1);
}
.block-color-purple_background {
  background: rgba(244, 240, 247, 0.8);
}
.block-color-pink_background {
  background: rgba(249, 238, 243, 0.8);
}
.block-color-red_background {
  background: rgba(253, 235, 236, 1);
}
.select-value-color-pink {
  background-color: rgba(245, 224, 233, 1);
}
.select-value-color-purple {
  background-color: rgba(232, 222, 238, 1);
}
.select-value-color-green {
  background-color: rgba(219, 237, 219, 1);
}
.select-value-color-gray {
  background-color: rgba(227, 226, 224, 1);
}
.select-value-color-opaquegray {
  background-color: rgba(255, 255, 255, 0.0375);
}
.select-value-color-orange {
  background-color: rgba(250, 222, 201, 1);
}
.select-value-color-brown {
  background-color: rgba(238, 224, 218, 1);
}
.select-value-color-red {
  background-color: rgba(255, 226, 221, 1);
}
.select-value-color-yellow {
  background-color: rgba(253, 236, 200, 1);
}
.select-value-color-blue {
  background-color: rgba(211, 229, 239, 1);
}

.checkbox {
  display: inline-flex;
  vertical-align: text-bottom;
  width: 16;
  height: 16;
  background-size: 16px;
  margin-left: 2px;
  margin-right: 5px;
}

.checkbox-on {
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Crect%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22%2358A9D7%22%2F%3E%0A%3Cpath%20d%3D%22M6.71429%2012.2852L14%204.9995L12.7143%203.71436L6.71429%209.71378L3.28571%206.2831L2%207.57092L6.71429%2012.2852Z%22%20fill%3D%22white%22%2F%3E%0A%3C%2Fsvg%3E');
}

.checkbox-off {
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Crect%20x%3D%220.75%22%20y%3D%220.75%22%20width%3D%2214.5%22%20height%3D%2214.5%22%20fill%3D%22white%22%20stroke%3D%22%2336352F%22%20stroke-width%3D%221.5%22%2F%3E%0A%3C%2Fsvg%3E');
}
</style>
