var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"profile-bundle"},[(_vm.members.length > 0)?_c('div',[_c('ul',_vm._l((_vm.members),function(val,i){return _c('li',{key:i},[_c('router-link',{attrs:{"to":{
            name: 'UserOrArtist',
            params: {
              id: val.username,
              wallet_address: val.wallet_address,
            },
          }}},[_c('ContentsProfile',{attrs:{"needUserName":_vm.needUserName,"member":val,"isFirstLoading":_vm.isFirstLoading}})],1)],1)}),0)]):_c('div',[_c('ul',[_c('li',[_c('ContentsProfile',{attrs:{"needUserName":_vm.needUserName}})],1),_c('li',[_c('ContentsProfile',{attrs:{"needUserName":_vm.needUserName}})],1),_c('li',[_c('ContentsProfile',{attrs:{"needUserName":_vm.needUserName}})],1),_c('li',[_c('ContentsProfile',{attrs:{"needUserName":_vm.needUserName}})],1),_c('li',[_c('ContentsProfile',{attrs:{"needUserName":_vm.needUserName}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }