var render = function render(){var _vm=this,_c=_vm._self._c;return _c('carousel',{attrs:{"perPageCustom":[
    [360, 1.9],
    [500, 2.5],
    [600, 3],
    [700, 4],
    [840, 4.5],
    [920, 5],
    [1080, 5.9],
    [1140, 6],
    [1440, 7.9],
    [1920, 8],
  ],"autoplay":false,"loop":true,"scrollPerPage":false,"paginationEnabled":false}},_vm._l((_vm.profiles),function(profile,i){return _c('slide',{key:i,staticClass:"slide"},[_c('router-link',{attrs:{"to":{
        name: 'UserOrArtist',
        params: {
          id: profile.username,
        },
      }}},[_c('ProfileCard',{attrs:{"profile":profile}})],1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }