var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.item.component)?_c(_vm.item.component,_vm._b({tag:"component"},'component',_vm.item.props,false)):(_vm.item.header)?_c('div',_vm._b({staticClass:"art--header",class:_vm.item.class},'div',_vm.item.attributes,false),[_vm._v(" "+_vm._s(_vm.item.title)+" ")]):_c('div',{staticClass:"art--item",class:[{ 'art--item_open': _vm.show }],on:{"mouseout":_vm.mouseLeaveEvent}},[_c('SidebarMenuLink',_vm._b({class:_vm.itemLinkClass,attrs:{"tag":_vm.item.disabled || !_vm.itemLinkHref
        ? 'span'
        : _vm.isRouterLink
        ? 'router-link'
        : 'a',"href":_vm.itemLinkHref,"disabled":_vm.item.disabled},nativeOn:{"click":function($event){return _vm.clickEvent.apply(null, arguments)}}},'SidebarMenuLink',_vm.item.attributes,false),[(_vm.item.icon)?_c('SidebarMenuIcon',{attrs:{"icon":_vm.item.icon}}):_vm._e(),[(_vm.item.badge)?_c('SidebarMenuBadge',{style:([_vm.item.child ? { 'margin-right': '30px' } : '']),attrs:{"badge":_vm.item.badge}}):_vm._e(),_c('span',{staticClass:"art--title"},[_vm._v(_vm._s(_vm.item.title))]),(_vm.item.child)?_c('div',{staticClass:"art--arrow",class:[
          { 'art--arrow_open': _vm.show },
          { 'art--arrow_slot': _vm.$slots['dropdown-icon'] },
        ]},[_vm._t("dropdown-icon")],2):_vm._e()]],2),(_vm.item.child)?[[(_vm.show)?_c('div',{staticClass:"art--dropdown"},[_c('div',{staticClass:"art--list"},_vm._l((_vm.item.child),function(subItem,index){return _c('sidebar-menu-item',{key:index,attrs:{"item":subItem,"level":_vm.level + 1,"show-child":_vm.showChild}},[_vm._t("dropdown-icon",null,{"slot":"dropdown-icon"})],2)}),1)]):_vm._e()]]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }