var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"box"},[_c('div',{staticClass:"symbol-and-slug"},[_c('div',{staticClass:"symbol"},[_vm._v(" "+_vm._s(_vm.project.symbol ? _vm.project.symbol.toUpperCase() : '')+" ")]),_c('div',{staticClass:"slug"},[_vm._v(_vm._s(_vm.project.slug ? `/${_vm.project.slug}` : ''))])]),_c('div',{staticClass:"collection-name"},[_vm._v(_vm._s(_vm.project.name))]),_c('router-link',{attrs:{"to":{
        name: 'UserOrArtist',
        params: {
          id: _vm.project.owner ? _vm.project.owner.username : '',
          wallet_address: _vm.project.owner ? _vm.project.owner.wallet_address : '',
        },
      }}},[_c('ContentsProfile',{staticClass:"contents-profile",attrs:{"member":_vm.project.owner}})],1),_c('div',{staticClass:"buttons1"},[(_vm.project.is_subscriber)?_c('button',{staticClass:"white-button",on:{"click":_vm.unsubscribe}},[_vm._v(" "+_vm._s(_vm.$t('views.project.buttons.unsubscribe'))+" "+_vm._s(this.project.subscribers)+" ")]):_c('button',{staticClass:"white-button",on:{"click":_vm.subscribe}},[_vm._v(" "+_vm._s(_vm.$t('views.project.buttons.subscribe'))+" "+_vm._s(this.project.subscribers)+" ")]),_c('button',{directives:[{name:"ripple",rawName:"v-ripple"}],on:{"click":_vm.contribute}},[_vm._v(" "+_vm._s(_vm.$t('views.project.buttons.contribute'))+" ")])]),_c('div',{staticClass:"detail"},[_c('div',{staticClass:"top"},[_vm._v(_vm._s(_vm.$t('views.project.statitics.items')))]),_c('div',{staticClass:"bottom"},[_vm._v(" "+_vm._s(_vm.project.max_token_id || 0)+"/"+_vm._s(_vm.project.maxAmount === '0' ? '∞' : _vm.project.maxAmount)+" ")])]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"info-title"},[_vm._v(" "+_vm._s(_vm.$t('views.project.statitics.description'))+" ")]),_c('div',{staticClass:"info-description"},[_vm._v(" "+_vm._s(_vm.project.description)+" ")])]),_c('SnsLinks',{attrs:{"sns":_vm.project.sns}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }