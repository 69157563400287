var render = function render(){var _vm=this,_c=_vm._self._c;return _c('carousel',{attrs:{"perPageCustom":[
    [250, 1],
    [600, 2],
    [1080, 3],
    [1440, 4],
  ],"autoplay":true,"autoplayHoverPause":true,"loop":true,"scrollPerPage":true,"paginationColor":"#B3B3B3","paginationActiveColor":"#F22E3E"}},_vm._l((_vm.projects),function(project,i){return _c('slide',{key:i},[_c('router-link',{attrs:{"to":{
        name: 'Project',
        params: {
          id: project.slug || project.id || project.address,
        },
      }}},[_c('ProjectCard',{attrs:{"project":project}})],1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }