var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":_vm.contentImage,"alt":""},on:{"click":function($event){$event.stopPropagation();return _vm.onContentClick.apply(null, arguments)}}}),(_vm.needContentName)?_c('ProjectPageProfile_small',{staticClass:"project-profile",attrs:{"project":_vm.projectWithAdditionals}}):_vm._e()],1),_c('div',{staticClass:"bottom"},[_c('router-link',{staticClass:"profile-link",attrs:{"to":{
        name: 'UserOrArtist',
        params: {
          id: _vm.content.owner
            ? _vm.content.owner.username
            : _vm.content.creator.username,
        },
      }}},[_c('ContentsProfile',{staticClass:"content-profile",attrs:{"member":_vm.content.owner ? _vm.content.owner : _vm.content.creator}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.price),expression:"price"}]},[_c('div',{staticClass:"price-title"},[_vm._v("PRICE")]),_c('div',{staticClass:"price"},[_vm._v(_vm._s(_vm.price)+" ETH")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }