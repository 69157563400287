<template>
  <article id="73faf7d1-0d44-41d7-ad39-f98284ffa565" class="page sans">
    <header>
      <h1 class="page-title">이용약관</h1>
    </header>
    <div class="page-body">
      <ul id="0ea0f79b-ade7-4863-9690-8e87922358c4" class="bulleted-list">
        <li style="list-style-type: disc">
          본 약관은 2023년 2월 28일로부터 적용됩니다.
        </li>
      </ul>
      <h3 id="fdee0fb5-75c6-47f8-b3a5-1af0aacb7988" class="">
        <strong>제1조 일반</strong>
      </h3>
      <p id="ab81d1dc-2761-423b-ab4c-22647def6925" class="">
        본 약관은 알통(이하 ”회사”)이 제공하는 알통 및 관련 제반 플랫폼의
        서비스(아래 정의) 이용과 관련하여 회사와 "회원"(아래 정의)의 권리, 의무
        및 책임사항 등을 규정함을 목적으로 합니다.
      </p>
      <h3 id="c992d0d8-9537-4b50-88d9-7acb9c77b708" class="">
        <strong>제2조 약관의 명시, 효력 및 변경</strong>
      </h3>
      <ul id="aa78a6ec-19a1-4963-9b15-4237a161c90d" class="bulleted-list">
        <li style="list-style-type: disc">
          회사는 본 약관의 내용을 서비스를 이용하고자 하는 자와 회원이 쉽게 알
          수 있도록 서비스 초기 화면에 게시합니다.
          <ul id="db4e3ce9-cdfe-442a-a433-b01675aac937" class="bulleted-list">
            <li style="list-style-type: circle">
              회사는 본 약관의 내용을 서비스를 이용하고자 하는 자와 회원이 쉽게
              알 수 있도록 서비스 초기 화면 하단에 게시합니다.
            </li>
          </ul>
        </li>
      </ul>
      <ul class="bulleted-list" :id="'eb1b9fc0-ed97-427f-b1aa-817f861938a2'">
        <li style="list-style-type: circle">
          회사는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한
          법률, 전자 문서 및 전자거래기본법, 전자금융거래법 등 관계법령을
          위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
        </li>
      </ul>
      <ul class="bulleted-list" :id="'315db42a-bcab-4959-b534-2dffecd64131'">
        <li style="list-style-type: circle">
          회사가 약관을 개정할 경우에는 적용일자 및 개정 사유를 명시하여 현행
          약관과 함께 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만,
          회원에게 불리한 약관의 개정의 경우에는 그 시행일자 최소한 30일 이상의
          유예기간을 두고 위 1항의 방법으로 공지합니다.
        </li>
      </ul>
      <ul class="bulleted-list" :id="'3bca3fe9-acb0-452c-8840-d11bcbe68220'">
        <li style="list-style-type: circle">
          약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는
          계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정 전의
          약관조항이 그대로 적용됩니다.
        </li>
      </ul>
      <ul class="bulleted-list" :id="'d78a6337-ef42-4a03-89f1-fe849636af43'">
        <li style="list-style-type: circle">
          회사가 전항에 따라 개정 약관을 공지 또는 통지일로부터 개정약관 시행일
          7일 후까지 거부의사를 표시하지 아니하면 동의한 것으로 본다는 뜻을
          명확하게 공지하였음에도 회원이 명시적으로 거부의 의사표시를 하지
          아니한 경우 회원이 개정 약관에 동의한 것으로 봅니다.
        </li>
      </ul>
      <h3 :id="'b9329e86-b970-427d-87c1-4f449a622859'">
        <strong>제3조 약관의 해석</strong>
      </h3>
      <ul class="bulleted-list" :id="'f78c4579-c273-4dcf-b8c3-b929467a885c'">
        <li style="list-style-type: disc">
          회사는 약관 외에 별도의 운영정책을 두어 서비스를 운영할 수 있습니다.
        </li>
      </ul>
      <ul class="bulleted-list" :id="'1a544ad7-44c0-4855-9a5e-35cce0ebcf6d'">
        <li style="list-style-type: disc">
          이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 정보통신망
          이용촉진 및 정보보호 등에 관한 법률, 전자상거래 등에서의 소비자보호에
          관한 법률, 약관의 규제에 관한 법률, 기타 관계법령, 상관례에 따릅니다.
        </li>
      </ul>
      <h3 :id="'7de4dce2-9e16-4abd-aadc-aa4f21884143'" class="">
        <strong>제4조 정의</strong>
      </h3>
      <p :id="'52886252-a990-41f4-a33c-ede2ebb06b68'" class="">
        가. 본 약관에서 사용하는 정의는 다음과 같습니다.
      </p>
      <ul class="bulleted-list" :id="'80bfe4ed-f401-4a9c-a119-d60e747859e7'">
        <li style="list-style-type: disc">
          서비스: 회사가 제공하는 www.artong.io 를 비롯한 알통 도메인의 웹사이트
          및 응용프로그램(어플리케이션, 앱)을 통해 회원이 매각하려는 객체를 NFT
          형식으로 생성하고 이를 판매하며, 회원의 NFT구매를 가능하게 하는
          서비스로 알통 서비스 및 관련 제반 플랫폼을 의미합니다.
        </li>
      </ul>
      <ul id="bd7764ce-43c9-418c-8ca2-264ad5eaaca6" class="bulleted-list">
        <li style="list-style-type: disc">
          회원: 이 약관에 따라 회사와 서비스이용계약을 체결하고 회사가 제공하는
          서비스를 이용하는 자를 말합니다.
        </li>
      </ul>
      <ul id="8e38cb16-958d-4e29-91b4-9f1dc37a66ae" class="bulleted-list">
        <li style="list-style-type: disc">
          NFT: 회사가 제공하는 서비스를 통해 디지털 아트, 음악, 강연권, 식사권,
          교환권 등이 블록체인 상에 생성된 고유의 속성값을 가지는 전자적 증표로
          발행된 것을 의미합니다 (‘서비스 이용가이드’ 상 “NFT작품”과 동일한
          의미입니다).
        </li>
      </ul>
      <ul id="7368c309-bfcf-4206-8bc5-538eecc2ebe5" class="bulleted-list">
        <li style="list-style-type: disc">
          생성: NFT를 생성할 수 있는 원본인 디지털 아트, 음악, 강연권, 식사권,
          교환권, 정보 등을 회사에서 제공하는 서비스(‘생성서비스’) 를 통해
          블록체인에 NFT 정보를 등록하여 NFT를 생성하는 것을 말합니다 (‘서비스
          이용가이드’ 상 “발행”과 동일한 의미입니다)
        </li>
      </ul>
      <ul id="aa3fe84f-9443-46dd-b316-416ff9145bd3" class="bulleted-list">
        <li style="list-style-type: disc">
          생성자: NFT를 생성한 자를 의미합니다. 생성된 NFT는 생성자의 동의가
          없는 한 변경될 수 없음을 원칙으로 합니다.
        </li>
      </ul>
      <ul id="6666c321-25ff-438e-931e-9061504feb33" class="bulleted-list">
        <li style="list-style-type: disc">
          판매자: 회사가 제공하는 서비스 중 판매 중개 기능(‘판매서비스’)을
          통하여 자신이 소지한 NFT를 판매하기 위해 회사가 제공하는 양식에 맞추어
          서비스에 NFT 판매 등록한 회원을 말합니다. 회사가 제공하는 판매 중개
          방식은 ‘일반판매’, ‘제시판매’ 로 제한되며 향후 방식을 다양화할
          예정입니다.
        </li>
      </ul>
      <ul id="e4fde5d9-a87d-4cf4-a3e1-341e9f75bb58" class="bulleted-list">
        <li style="list-style-type: disc">
          구매자: 회사가 제공하는 서비스를 통해 판매자의 NFT를 구매할 의사를
          표시하여 서비스를 통해 판매자로부터 NFT를 양수한 회원을 말합니다.
        </li>
      </ul>
      <ul id="d81f759e-a512-4dae-a021-611d626896d1" class="bulleted-list">
        <li style="list-style-type: disc">
          제시: NFT를 구매하기 위해 서비스를 통해 원하는 매수가격을 제출하는
          방식으로 구매의사를 표시하는 행위를 말합니다.
        </li>
      </ul>
      <ul id="af7ca8b1-63f5-40bb-9ee1-b33739281d31" class="bulleted-list">
        <li style="list-style-type: disc">
          수락: 제시 수락에 의하여 NFT의 거래가 성립되는 것을 말합니다.
        </li>
      </ul>
      <ul id="68368981-b9ac-4791-b4d4-b8c666cb8e7f" class="bulleted-list">
        <li style="list-style-type: disc">
          판매: 서비스의 판매 중개 기능을 통해 판매자가 구매자에게 NFT를
          매도하는 것을 말합니다.
        </li>
      </ul>
      <ul id="4a98871f-9d68-4656-9004-669235effd90" class="bulleted-list">
        <li style="list-style-type: disc">
          결제서비스: 회사가 회원 상호간 NFT 거래 시 그 거래 수단인 ‘결제
          토큰’의 전송을 위해 제공하는 결제서비스 및/또는 관련 부가서비스 일체
          시스템 및 절차를 말합니다. 본 서비스 상의 결제 토큰은 현재
          이더(ETH)이며, 향후 다른 결제 토큰이 추가될 수 있습니다.
        </li>
      </ul>
      <ul id="4c029e38-9c09-41c0-8635-1760ff74f5f8" class="bulleted-list">
        <li style="list-style-type: disc">
          직거래: 서비스를 통하지 않고 판매자와 구매자가 직접 NFT의 소유권
          이전을 위해 금전 또는 가상자산을 주고받는 것을 의미합니다.
        </li>
      </ul>
      <ul id="ce4653e5-75a1-4d65-b282-49e0e3d5063f" class="bulleted-list">
        <li style="list-style-type: disc">
          닉네임: 서비스 이용을 위해, 본 약관 및 개인정보처리방침에 동의한
          회원이 정하고 회사가 수집하는 회원의 고유한 식별 명칭(문자와 숫자의
          조합)을 말합니다.
        </li>
      </ul>
      <ul id="51c20eb5-4db6-4eb1-9203-79e0d25cbce0" class="bulleted-list">
        <li style="list-style-type: disc">
          블록체인: 전자적인 블록에 데이터를 담아 각 블록을 체인과 같이
          순차적으로 연결하는 기술로, 누구나 열람할 수 있는 장부에 거래 내역을
          기록하고 블록체인 네트워크에 참여한 각 컴퓨터에 이를 복제하여 저장하는
          분산형 데이터 저장 방식을 말합니다.
        </li>
      </ul>
      <ul id="c6651fdb-ad92-4058-9e29-bde654abbd45" class="bulleted-list">
        <li style="list-style-type: disc">
          스마트 컨트랙트: 블록체인 위에서 프로그램으로 동작 가능한 언어로
          작성된 코드 뭉치를 말합니다.
        </li>
      </ul>
      <ul id="1938356c-f84f-44c9-8965-ddfd12553d75" class="bulleted-list">
        <li style="list-style-type: disc">
          DID: 회사 서비스 가입 및 로그인 시 이용되며 블록체인과 메타마스크,
          레인보우 등의 지갑 서비스를 통해 회원에게 발급되는 고유한 익명 ID를
          의미합니다.
        </li>
      </ul>
      <ul id="2005cbae-0f68-4993-ab82-f2e14fcddbe0" class="bulleted-list">
        <li style="list-style-type: disc">
          이용수수료: 서비스를 통해 제공되는 기본 서비스 및 부가서비스 이용료를
          말합니다.
        </li>
      </ul>
      <ul id="b1d09daa-5680-4c88-96b9-474ad2a0e062" class="bulleted-list">
        <li style="list-style-type: disc">
          디지털자산: 서비스를 통해 전자적인 방법으로 가치의 저장 수단, 교환의
          매개가 되는 것으로 코인, 토큰, NFT 등을 포함한 블록체인 상의 모든
          데이터를 의미합니다.
        </li>
      </ul>
      <ul id="f7477e6a-ce8a-47d6-942b-25c7951ad40e" class="bulleted-list">
        <li style="list-style-type: disc">
          계정주소: 서비스에서 회원 간 디지털자산의 변화를 기록하기 위해
          블록체인 상에 존재하는 고유한 식별 주소를 말합니다.
        </li>
      </ul>
      <ul id="436bc7dc-5548-40cd-b797-5ad1285ae0bc" class="bulleted-list">
        <li style="list-style-type: disc">
          잔액: 알통 잔액, 제시 잔액, 인출가능 잔액 세가지로 표현됩니다. 각각
          ETH를 기본 단위로 하며 알통 잔액 = 인출가능 잔액 + 제시 잔액 입니다.
          제시 이후 24시간이 지나도 수락되지 않는다면 제시 잔액은 알통 잔액으로
          합산 됩니다.
        </li>
      </ul>
      <ul id="aa36757f-9182-4dee-a109-89ffa3903349" class="bulleted-list">
        <li style="list-style-type: disc">
          인출: 인출가능 잔액 0.01ETH 이상이 되었을때 해당 인출가능 잔액을 인출
          가능합니다.
        </li>
      </ul>
      <p id="c87519ec-ce18-4491-a246-49865480a797" class="">
        나. 위 가항에서 정의되지 않은 이 약관 상의 용어의 의미는 일반적인
        거래관행에 의합니다.
      </p>
      <h3 id="f29f15e9-d30e-4ee9-ae43-a0d5139b5bec" class="">
        <strong>제5조 생성, 판매 및 결제의 성질과 목적</strong>
      </h3>
      <ul id="cf4f11ca-bd61-4c41-8c92-53bcfc296e3d" class="bulleted-list">
        <li style="list-style-type: disc">
          생성서비스는 회원이 직접 NFT를 생성하고 관리할 수 있는 기능을 회사가
          제공하는 것입니다. 그러나 회사는 생성서비스를 제공하는 외에 회원의
          NFT생성 또는 관리를 대행하거나 위탁 받아 운영하지 않습니다.
        </li>
      </ul>
      <ul id="057cad8d-f880-449f-a5a4-96af4103f5de" class="bulleted-list">
        <li style="list-style-type: disc">
          판매서비스는 회사가 회원 각자의 자기결정에 의하여 회원 상호간에 NFT
          거래가 이루어질 수 있도록 온라인 거래 장소(마켓플레이스,
          Marketplace)를 제공하는 것이며, 결제서비스는 회원 상호간에 NFT 거래에
          있어 안전하고 편리한 결제 토큰 전송이 이루어질 수 있는 수단을 제공하는
          것입니다. 회원 간 거래와 관련된 책임은 거래 당사자인 회원들 스스로가
          부담합니다.
        </li>
      </ul>
      <ul id="a9bd464a-574d-47ab-a456-5620446e4e4b" class="bulleted-list">
        <li style="list-style-type: disc">
          회원이 서비스 이용에 필요한 디지털자산 보관 및 관리, DID 인증
          소프트웨어 및 관련/제반 서비스(‘메타마스크’, ‘레인보우’ 지갑 서비스)의
          이용은 해당 서비스의 약관에 따르며, 회원은 안정적인 서비스 이용을
          위하여 해당 소프트웨어 및 관련/제반 서비스 데이터에 대한 백업 및 저장
          의무가 있습니다. 또, 회사는 회원의 부주의로 인한 발생한 해당
          소프트웨어 및 관련/제반 서비스 데이터 손실로 인한 손해에 대하여 책임을
          부담하지 아니합니다.
        </li>
      </ul>
      <ul id="ea607ab1-f77b-457e-9f0f-ec061474e65a" class="bulleted-list">
        <li style="list-style-type: disc">
          회원이 구매자로서 제시하기를 통해 행한 거래 제안은 24시간 동안
          유지됩니다. 제시를 통해 서비스에 입금된 토큰은 제시 잔액에 24시간동안
          유지되며 24시간 이내에 판매자가 이를 수락하지 않으면 알통 잔액으로
          편입되어 기존 알통 잔액에 합산됩니다. 또한 제시가 이루어진 경우 철회가
          불가합니다.
        </li>
      </ul>
      <h3 id="8b9ecaf4-a247-4349-b30f-370db50ea2f2" class="">
        <strong>제6조 이용계약 체결</strong>
      </h3>
      <ul id="edc6c591-c57d-4672-8c1d-8a0ec5158914" class="bulleted-list">
        <li style="list-style-type: disc">
          이용계약은 회원이 되고자 하는 자(이하 &quot;가입신청자&quot;라
          합니다)가 회사가 제공하는 약관의 내용에 대하여 동의를 한 다음 회사가
          정한 절차에 따라 가입을 신청하고, 회사가 이 신청을 승낙함으로써
          체결됩니다.
        </li>
      </ul>
      <ul id="e73d5662-c171-4d33-953c-b4b82043f2c0" class="bulleted-list">
        <li style="list-style-type: disc">
          회사는 가입신청자의 신청에 대하여 가입신청자에게 아래 각 호에 해당하는
          사유가 있는 경우에는 승낙을 하지 않을 수 있으며, 가입 이후에도 아래 각
          호의 사유가 확인될 경우에는 승낙을 취소하거나 이용계약을 해지할 수
          있습니다.
          <ul id="18a8602e-19ef-4c3d-a12f-2ab439dd3044" class="bulleted-list">
            <li style="list-style-type: circle">
              이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우
            </li>
          </ul>
          <ul id="104f8a6f-4172-4908-b879-5d64e3b43f02" class="bulleted-list">
            <li style="list-style-type: circle">
              실명이 아닌 명의 또는 타인의 명의를 이용한 경우
            </li>
          </ul>
          <ul id="44dcba25-b14a-4c0c-8e46-37e4f065b0d2" class="bulleted-list">
            <li style="list-style-type: circle">
              허위 또는 잘못된 정보를 기재 또는 제공하거나, 회사가 이용계약
              체결을 위해 요청하는 내용을 제공하지 않은 경우
            </li>
          </ul>
          <ul id="1bb872e9-c6fc-400f-9177-6a1866ef15ca" class="bulleted-list">
            <li style="list-style-type: circle">
              가입신청자의 귀책사유로 인하여 승인이 불가능하거나 기타 이
              약관에서 규정한 제반 사항을 위반하며 신청하는 경우
            </li>
          </ul>
          <ul id="aa6e6e15-5629-455e-8734-ad843416c36f" class="bulleted-list">
            <li style="list-style-type: circle">
              부정한 용도 또는 별개의 영업 목적으로 서비스를 이용하고자 하는
              경우
            </li>
          </ul>
          <ul id="450f0e14-3efe-4e1a-b2bb-a23f002b0b0e" class="bulleted-list">
            <li style="list-style-type: circle">
              관련법령에 위배되거나 사회의 안녕질서 혹은 미풍양속을 저해할 수
              있는 목적으로 신청한 경우
            </li>
          </ul>
          <ul id="9669f126-d3fa-4662-bd50-d4792e97b17d" class="bulleted-list">
            <li style="list-style-type: circle">
              이용 계약 당사자가 서비스 이용을 제한하는 특정 국가의 외국인이거나
              민법상 미성년자일 경우
            </li>
          </ul>
          <ul id="2d3f898e-1390-42ac-8bc1-d273952ad309" class="bulleted-list">
            <li style="list-style-type: circle">
              기타 이 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된
              경우 또는 회사가 합리적인 판단에 의하여 필요하다고 인정하는 경우
            </li>
          </ul>
        </li>
      </ul>
      <ul id="c254420a-14c6-47a2-a9c3-ea14fa65bc1f" class="bulleted-list">
        <li style="list-style-type: disc">
          이용계약의 성립 시기는 회사가 계정 생성 완료를 신청절차상 혹은
          서비스화면에 표시하거나 회사가 회원에게 이메일을 통해 통지한 시점으로
          합니다.
        </li>
      </ul>
      <ul id="e860e03c-ac82-4609-bb6b-31f3443455d8" class="bulleted-list">
        <li style="list-style-type: disc">
          회사는 회원이 제공한 정보가 사실과 일치하는지 여부를 확인하기 위하여
          법령에 의하여 허용된 범위에서 전문기관을 통한 실명확인 또는 본인인증을
          요청할 수 있습니다.
        </li>
      </ul>
      <h3 id="1d4c194f-77e2-4f14-8d7e-68909f6b3b27" class="">
        <strong>제7조 이용계약 해지</strong>
      </h3>
      <ul id="ea8580db-70b5-4a30-909c-059207511721" class="bulleted-list">
        <li style="list-style-type: disc">
          회원은 언제든지 고객센터 등을 통하여 이용계약 해지를 신청할 수 있으며,
          회사는 관련 법령이 정하는 바에 따라 이를 즉시 처리합니다. 다만, 회원은
          해지의사를 통지하기 전에 진행 중인 모든 구매 또는 판매 절차를 다음
          절차에 따라 완료하거나, 판매 의사를 철회하거나, 진행 중인 구매 또는
          판매 절차를 취소해야만 합니다. 위 철회 또는 취소, 종료로 인한 불이익이
          발생하는 경우 회원이 이를 부담합니다. 회원이 다음 절차를 따르지 않고
          이용계약 해지를 신청하여 발생한 불이익은 당해 회원이 부담합니다.
          <ul id="6108b955-67ab-403a-89b7-ce53a5026e26" class="bulleted-list">
            <li style="list-style-type: circle">
              회원이 이용계약을 해지하기 위해서는 판매 중인 NFT가 없어야 합니다.
              회사가 제공하는 서비스를 통해 판매 중인 NFT가 존재하는 경우
              이용계약의 해지는 해당 NFT에 대한 제시자가 있는 경우 판매 완료
              또는 제시자가 없는 경우 판매의 철회가 선행되어야 가능합니다.
            </li>
          </ul>
          <ul id="da408ddd-5871-49b8-9af9-4307c1dc11c8" class="bulleted-list">
            <li style="list-style-type: circle">
              회원이 이용계약을 해지하기 위해서는 회원은 판매가 완료된 NFT에
              대한 양도절차를 완료하여야 하며, 제시회원이 있는 경우 판매 절차를
              완료한 이후 이용계약의 해지가 가능합니다.
            </li>
          </ul>
          <ul id="5ceaaa08-a042-4667-a571-a7325a8f9ff6" class="bulleted-list">
            <li style="list-style-type: circle">
              회원이 NFT생성만을 진행하고 판매에 나아가지 않은 상태에서는 언제든
              이용계약의 해지가 가능합니다.
            </li>
          </ul>
          <ul id="addb27dc-582f-42c7-a4f3-1a989a671b9b" class="bulleted-list">
            <li style="list-style-type: circle">
              회원이 NFT를 구매하기 위해 가격을 제시한 경우, 회원은 해당
              제시하기가 종료되기 전 이용계약의 해지가 원칙적으로 불가능합니다.
            </li>
          </ul>
          <ul id="22acc618-b40b-4cd2-a6ee-581ffb0aa734" class="bulleted-list">
            <li style="list-style-type: circle">
              회사는 회원이 NFT 구매 이후 이용계약을 해지하는 경우 NFT에 관한
              환불을 하지 않습니다.
            </li>
          </ul>
        </li>
      </ul>
      <ul id="3ff87660-334f-4296-9755-de2621e071ea" class="bulleted-list">
        <li style="list-style-type: disc">
          전항에 따라 이용계약을 해지한 회원은 이 약관이 정하는 회원가입절차와
          관련조항에 따라 회원으로 다시 가입할 수 있습니다. 다만 회원 재가입
          신청이 특정 이벤트 중복 참여 등 부정한 목적으로 이루어진 경우 회사는
          재가입을 일정기간 동안 제한할 수 있습니다.
        </li>
      </ul>
      <ul id="331eb13b-76b8-42a0-b635-8096500cb818" class="bulleted-list">
        <li style="list-style-type: disc">
          회사는 회원에게 다음과 같은 사유가 발생할 경우 회원의 이용계약을
          해지하거나 회원자격을 정지하거나 서비스 이용을 제한할 수 있습니다.
          <ul id="bbe7b4bb-1773-4e88-b08e-b345947ea79d" class="bulleted-list">
            <li style="list-style-type: circle">본 약관을 위반한 경우</li>
          </ul>
          <ul id="68503627-330f-4ff3-8fbe-899811f84b69" class="bulleted-list">
            <li style="list-style-type: circle">
              회원이 12조 3항에 해당하는 내용으로 NFT를 생성하거나 기타 공공질서
              및 미풍양속에 위배되는 거래행위를 하거나 시도한 경우
            </li>
          </ul>
          <ul id="d4b57b1e-8f85-41e3-be41-ab6fa54a0e3f" class="bulleted-list">
            <li style="list-style-type: circle">
              회원이 회사나 다른 회원, 기타 타인의 권리나 명예, 신용 기타 정당한
              이익을 침해하는 행위를 한 경우
            </li>
          </ul>
          <ul id="4ab87233-d172-4517-8aac-0c2cb6614205" class="bulleted-list">
            <li style="list-style-type: circle">
              저작권법을 위반한 불법프로그램의 제공 및 운영방해,
              정보통신망이용촉진및정보보호등에관한법률을 위반한 불법통신 및
              해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련 법령을
              위반한 경우
            </li>
          </ul>
          <ul id="80eeb5eb-0ad2-4e15-aa9f-b83352518519" class="bulleted-list">
            <li style="list-style-type: circle">
              회원이 직거래, 직거래 권유 등 회사가 제공하는 서비스의 운영을
              방해하는 경우
            </li>
          </ul>
          <ul id="b8afa154-e2e8-485c-bf73-a06681ef2bd6" class="bulleted-list">
            <li style="list-style-type: circle">
              회사에서 수집한 데이터를 사적으로 유용하는 경우
            </li>
          </ul>
          <ul id="ff9a67de-ac18-4689-b8c8-5fdac9cbbe8a" class="bulleted-list">
            <li style="list-style-type: circle">
              생성된 NFT에 대해 법원, 저작권위원회 등이 타인의 저작권을 침해하는
              것으로 확인하거나, 회사가 (회원 또는 제3자의 요청 등에 의한 확인을
              포함합니다) 타인의 저작권을 침해한다고 판단한 경우
            </li>
          </ul>
          <ul id="3af9591d-61cb-4da1-8731-78a8540f7c77" class="bulleted-list">
            <li style="list-style-type: circle">
              그 외 운영정책, 이용자가이드 또는 법령에 위반되거나 타인의 권리를
              침해하는 행위가 있거나 그러한 위법·부당한 행위를 한 것으로 의심될
              만한 상당한 이유가 있는 경우
            </li>
          </ul>
          <ul id="f2215942-d3cf-46fe-ad8f-f44ba8c6e1e6" class="bulleted-list">
            <li style="list-style-type: circle">
              기타 회사가 합리적인 판단에 기하여 서비스의 제공을 거부할 필요가
              있다고 인정할 경우
            </li>
          </ul>
        </li>
      </ul>
      <ul id="6d6dec05-5417-4e53-9f73-8f44dbde1103" class="bulleted-list">
        <li style="list-style-type: disc">
          전항에 따른 이용계약 해지 시 서비스 이용을 통해 획득한 모든 혜택이
          소멸되며, 회사는 별도의 통지 없이 해당 회원과 관련된 판매를 취소할 수
          있습니다. 회사는 이에 대해 별도로 보상하지 않습니다.
        </li>
      </ul>
      <ul id="0f5657ec-1b03-4387-b427-35610922aff1" class="bulleted-list">
        <li style="list-style-type: disc">
          이용계약 해지가 완료되는 경우 관련 법령 및 개인정보처리방침에 따라
          회사가 보유하여야 하는 정보를 제외한 회원의 모든 정보가 삭제됩니다.
        </li>
      </ul>
      <ul id="da01dad7-9da6-4be8-94a8-cc20ec899d5c" class="bulleted-list">
        <li style="list-style-type: disc">
          전항에도 불구하고 제3항에 따라 회사가 이용계약을 해지하는 경우 회사는
          회원의 이의신청 접수 및 처리 등을 위하여 일정 기간 동안 회원의 정보를
          보관할 수 있으며, 해당 기간이 경과한 후에 회원의 정보를 삭제합니다.
        </li>
      </ul>
      <h3 id="05260aaa-01c9-4ad9-85c0-ff6effa80547" class="">
        <strong>제8조 회원정보 관리</strong>
      </h3>
      <ul id="5d57874b-9b1e-40c2-8c34-d679f83f0511" class="bulleted-list">
        <li style="list-style-type: disc">
          회원은 서비스를 통해 언제든지 본인의 개인정보를 열람하고 수정할 수
          있습니다. 다만, 서비스 관리를 위해 필요한 일부 정보는 수정이
          불가합니다.
        </li>
      </ul>
      <ul id="1ee104bf-514c-4afd-a496-20d4a488780a" class="bulleted-list">
        <li style="list-style-type: disc">
          회사는 회원이 등록한 DID 인증방식을 통해, 로그인 및 서비스를 이용하는
          별도의 확인절차 없이 이용자를 회원으로 간주합니다.
        </li>
      </ul>
      <ul id="3a69e3f5-7970-4335-b95f-ba9b1b17ce35" class="bulleted-list">
        <li style="list-style-type: disc">
          회원은 계정 접근정보를 분실했거나, 도용 당했거나, 제 3자에게
          공개되었음을 인지한 경우 이를 즉시 회사에 통지해야 합니다. 이 경우
          회사는 즉시 계정 이용중단 등의 조치를 취하며, 회원이 회사에게 통지하지
          않아 발생한 손해에 대해 회사는 책임을 지지 않습니다.
        </li>
      </ul>
      <ul id="d7988420-9649-4590-9507-f63dbfb15618" class="bulleted-list">
        <li style="list-style-type: disc">
          회원은 자신의 계정을 다른 사람에게 판매, 양도, 대여 또는 담보로
          제공하거나 다른 사람에게 사용을 허락하여서는 안됩니다. 회원은 다른
          사람이 회원의 계정에 무단으로 접근하지 않도록, 마이키핀을 이용한 DID
          인증을 포함하여 회원 계정 접근을 위해 필요한 일체의 정보와 방법을
          안전하게 보관할 책임이 있습니다.
        </li>
      </ul>
      <ul id="54aae19a-24d2-4a94-8e17-628a95fa3305" class="bulleted-list">
        <li style="list-style-type: disc">
          회원의 접근정보가 분실, 도용 혹은 공개되어 회원에게 발생한 손해에
          대하여 회사는 책임을 부담하지 아니합니다. 다만, 회사의 고의 또는
          과실에 의한 경우에는 그러하지 아니합니다.
        </li>
      </ul>
      <ul id="8d51811b-1b9f-4893-bc42-8c3719d65619" class="bulleted-list">
        <li style="list-style-type: disc">
          회사는 회원의 닉네임이 개인정보 유출 우려가 있거나, 회원 본인이 아닌
          타인으로 오해할 소지가 있거나, 반사회적 또는 미풍양속에 어긋나거나, 제
          3자의 권리를 침해하거나, 회사 및 회사의 운영자로 오인할 우려가 있는
          경우, 해당 닉네임의 이용을 제한할 수 있습니다. 또한 회사는 이 외의
          경우라도 회사가 합리적인 판단에 의하여 필요하다고 인정하는 경우 해당
          닉네임 사용을 제한할 수 있습니다.
        </li>
      </ul>
      <h3 id="1b840845-a5d5-4dbd-b44b-7fc43b65900c" class="">
        <strong>제9조 개인정보의 보호</strong>
      </h3>
      <p id="f7996c9a-2377-4950-aca1-228ccd1f2956" class="">
        회사는 정보통신망이용촉진및정보보호등에관한법률, 개인정보보호법 등 관련
        법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다.
        개인정보의 보호 및 이용에 대해서는 관련 법령 및 회사의
        개인정보처리방침이 적용됩니다. 다만, 회사가 제작하여 제공한 화면 이외에
        외부로 링크된 화면 등에서는 회사의 개인정보처리방침이 적용되지 않습니다.
      </p>
      <h3 id="3dcad8f3-55db-4711-8867-d3503797c3fb" class="">
        <strong>제10조 서비스 및 시스템 제공 등</strong>
      </h3>
      <ul id="b3ee822a-b0c1-4b36-991a-d37cb3ec193e" class="bulleted-list">
        <li style="list-style-type: disc">
          회사는 서비스 및 시스템을 제공함에 있어 범위, 이용 가능시간 및 횟수
          등을 지정할 수 있으며, 회원은 회사가 정하는 서비스 제공 조건에 따라
          서비스를 이용할 수 있습니다.
        </li>
      </ul>
      <ul id="469b077f-d381-4355-bc15-7daedf759964" class="bulleted-list">
        <li style="list-style-type: disc">
          회사는 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상
          합리적인 이유가 있는 경우 서비스의 제공을 일시적으로 중단할 수
          있습니다. 이 경우 회사는 제20조에 따른 방법으로 회원에게 통지합니다.
          다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에
          통지할 수 있습니다.
        </li>
      </ul>
      <ul id="39a85289-53a9-4c3e-aaae-4a5b11174edb" class="bulleted-list">
        <li style="list-style-type: disc">
          회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며,
          정기점검시간은 서비스제공화면에 공지한 바에 따릅니다. 또한, 정기점검
          시 서비스 이용 일부 또는 전체에 대하여 제한이 발생할 수 있으며, 회사의
          고의 또는 과실이 아닌 한 그로 인해 발생한 손해에 대해서는 책임을 지지
          않습니다.
        </li>
      </ul>
      <ul id="47915e00-daef-4a23-bf0d-c08ee2fe713a" class="bulleted-list">
        <li style="list-style-type: disc">
          회원이 NFT 생성, 판매, 결제 등 서비스 이용 시 입력 또는 등록한 정보의
          정확성에 대한 책임은 회원에게 있으며, 회사는 회원의 잘못으로 인하여
          발생하는 문제에 대해서는 책임 지지 않습니다.
        </li>
      </ul>
      <ul id="bdb4c498-b802-4a2d-8e0a-c0b5183fde07" class="bulleted-list">
        <li style="list-style-type: disc">
          서비스와 연계된 제3자 서비스 등의 장애 또는 점검 등의 사유로 서비스 내
          일부 기능에 제한이 발생하는 문제에 대해서는 회사의 고의, 과실이 없는
          한 회사는 어떠한 책임도 지지 않습니다. 이 경우, 회사는 회원에게
          제20조의 방법으로 해당 사실을 통지합니다.
        </li>
      </ul>
      <h3 id="50b34c40-3c4d-4ab1-8fba-a39f6d02c323" class="">
        <strong>제11조 서비스의 변경 및 중단</strong>
      </h3>
      <ul id="1e3b916b-fb65-46db-9ec5-bd6fbaafcb6c" class="bulleted-list">
        <li style="list-style-type: disc">
          회사는 다음과 같은 경우 또는 이에 해당하는 사유가 존재한다고 볼 만한
          합리적 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는
          전부 또는 일부 서비스를 변경할 수 있습니다.
          <ul id="d9b4dbaa-d1ea-4a23-9975-073085eccb75" class="bulleted-list">
            <li style="list-style-type: circle">
              회사가 안정적인 서비스 제공을 위하여 서비스의 내용, 운영상, 기술상
              관리 혹은 변경 혹은 관리가 필요하다고 판단하는 경우
            </li>
          </ul>
          <ul id="67cdce06-1442-412b-8aa7-36c7a9cdaee5" class="bulleted-list">
            <li style="list-style-type: circle">
              서비스를 이용한 불법적인 행위가 발생한 경우
            </li>
          </ul>
          <ul id="7cd6da44-a684-4b03-8d8f-9cbb6f9f9eaa" class="bulleted-list">
            <li style="list-style-type: circle">
              컴퓨터 등 정보통신설비의 보수점검, 장비의 교체 및 고장, 통신의
              두절 등의 사유가 발생한 경우
            </li>
          </ul>
          <ul id="97114cfb-9fa7-49df-bf85-bde0c9f6ca15" class="bulleted-list">
            <li style="list-style-type: circle">
              서비스를 위한 설비 등의 유지보수 등으로 인한 부득이한 사정이 있는
              경우
            </li>
          </ul>
          <ul id="a975561d-0d9a-4caf-9224-b03c0085f89e" class="bulleted-list">
            <li style="list-style-type: circle">
              정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스
              이용에 지장이 있는 경우 또는 지역 등 범위적인 네트워크 장애가
              발생하는 경우
            </li>
          </ul>
          <ul id="19d54261-d115-44cc-b1ab-6de4304bd438" class="bulleted-list">
            <li style="list-style-type: circle">
              회사의 운영정책에 반하는 회원의 행위가 있는 경우
            </li>
          </ul>
          <ul id="3a2d32c5-dc37-459e-bfcf-27f3fa4115ba" class="bulleted-list">
            <li style="list-style-type: circle">
              경영상 이유 등으로 서비스를 제공할 수 없는 부득이한 사정이 발생한
              경우
            </li>
          </ul>
          <ul id="70e9a158-5727-4feb-a5ac-6e6a2385b305" class="bulleted-list">
            <li style="list-style-type: circle">
              기타 천재지변, 국가비상사태, 각 국가의 법령/정책 상의 규제 또는
              제한 등 불가항력적 사유가 있는 경우
            </li>
          </ul>
        </li>
      </ul>
      <ul id="de30556c-d4ab-4dd0-86de-e48951401ae1" class="bulleted-list">
        <li style="list-style-type: disc">
          서비스의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는
          변경사유, 변경될 서비스의 내용 및 일시 등을 제20조에 따른 방법으로
          회원에게 사전통지 합니다. 단, 회사가 통제할 수 없는 사유 또는 긴급사유
          등으로 인하여 회원에 대한 공지 또는 통지가 불가능한 경우에는 먼저
          서비스 내용을 변경한 후 통지할 수 있습니다.
        </li>
      </ul>
      <ul id="ad3bf2e4-4f53-463b-a4a8-cea47a3270b7" class="bulleted-list">
        <li style="list-style-type: disc">
          회사는 제공하는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상
          수정, 중단, 변경할 수 있으며, 회원은 서비스 변경에 동의하지 않을 경우
          회사에 거부 의사를 표시하고 이용계약을 해지할 수 있습니다. 회사는 본
          서비스의 변경, 중단으로 발생하는 문제에 대해서 회사의 고의 또는 과실이
          없는 한 어떠한 책임도 지지 않습니다.
        </li>
      </ul>
      <h3 id="d6f1d47b-6dc4-4797-99cd-1a3614eb9043" class="">
        <strong>제12조 NFT 생성서비스의 이용</strong>
      </h3>
      <ul id="243e7c1a-f870-4aa9-ad50-eb1c56692bc8" class="bulleted-list">
        <li style="list-style-type: disc">
          회사가 제공하는 NFT 생성서비스는 Ethereum Network 등 블록체인 상에서
          취급되는 NFT를 생성하는 것으로 세부적인 서비스의 종류와 내용은 변경될
          수 있습니다. 회사는 생성 절차와 방법 등에 대한 사항을 ‘서비스
          이용가이드’를 통해 공지하며, 회원은 회사가 공지한 생성에 관한 사항을
          사전에 충분히 이해하고 NFT생성서비스를 이용하여야 합니다. 회사는
          회원이 관련 사항을 충분히 숙지하지 아니하여 발생하는 문제에 대해서는
          회사의 고의 또는 과실이 없는 한 어떠한 책임도 지지 않습니다.
        </li>
      </ul>
      <ul id="41074e04-37ef-4c17-ab54-26a5e722969c" class="bulleted-list">
        <li style="list-style-type: disc">
          회사는 회원이 다음 각 호의 내용을 포함하여 NFT를 생성한 사실을 직접
          인지하였거나 제3자의 신고로 인지한 경우 회사의 확인 ∙ 검토 결과에 따라
          별도의 사전 고지 없이 NFT를 서비스 상 노출되지 않게 하고 NFT에 대한
          소각을 요청할 수 있으며, 18조에 따라 판매 서비스 이용의 제한,
          회원자격의 정지 또는 이용계약을 해지할 수 있습니다.
          <ul id="73ec47be-6ae8-40d4-8b61-b4e98776e32d" class="bulleted-list">
            <li style="list-style-type: circle">
              욕설, 비속어, 은어 등이나 타인에게 성적 수치심이나 불쾌감·혐오감을
              유발할 수 있는 내용
            </li>
          </ul>
          <ul id="514b8d92-91b5-4e38-a8a7-4443a6e2509b" class="bulleted-list">
            <li style="list-style-type: circle">
              지역·장애·인종·출신국가·성별·나이·직업·종교 등을 차별하거나 이에
              대한 편견을 조장하는 내용
            </li>
          </ul>
          <ul id="428889cb-c296-4966-98aa-b88327df119b" class="bulleted-list">
            <li style="list-style-type: circle">
              불법적인 사행성, 도박 관련 내용, 불법 제품 또는 인터넷에서 판매
              금지된 물품을 홍보하거나 이를 암시하는 내용
            </li>
          </ul>
          <ul id="9a07d6e0-4587-4b61-af5f-35dc20fa49c6" class="bulleted-list">
            <li style="list-style-type: circle">
              타인의 지식재산권(특허권, 상표권, 저작권 등)을 침해하거나
              국내외에서 널리 알려진 타인의 상표, 상호 등을 도용한 내용
            </li>
          </ul>
          <ul id="0518d078-378e-4927-812d-70604743d8c6" class="bulleted-list">
            <li style="list-style-type: circle">
              투자상품으로 해석되거나 투자상품성 요소가 내포된 내용
            </li>
          </ul>
          <ul id="390cffa9-f10e-4931-8cb1-783b418b2c45" class="bulleted-list">
            <li style="list-style-type: circle">
              위법 행위에 대한 동기 부여 및 실행에 도움이 되는 내용
            </li>
          </ul>
          <ul id="c461b364-09b2-4f3f-a485-88bcb0e9123f" class="bulleted-list">
            <li style="list-style-type: circle">
              특정 인물이나 서비스의 명칭 등을 사칭하는 내용
            </li>
          </ul>
          <ul id="d6544cd5-b1dc-4ce6-be80-d15fe9628beb" class="bulleted-list">
            <li style="list-style-type: circle">
              그 외 회사가 판단했을 때 상식과 사회 통념에 반하는 내용
            </li>
          </ul>
        </li>
      </ul>
      <ul id="02a25ef4-1d91-41df-906b-e3b140010562" class="bulleted-list">
        <li style="list-style-type: disc">
          전항에 따라 NFT가 차단 또는 삭제된 경우와 이로 인해 판매가 취소되거나
          중지된 경우, 그 생성 및 판매서비스에 이용된 블록체인 이용료(Gas Fee)는
          환불되지 않으며, 또 당해 NFT생성자가 NFT의 차단 또는 삭제 등으로 인해
          발생한 민형사상 재산 피해에 대한 책임을 부담합니다.
        </li>
      </ul>
      <ul id="1833436a-028e-474d-a1df-66cf4a5f9d93" class="bulleted-list">
        <li style="list-style-type: disc">
          회사는 회원이 간편하게 NFT를 생성하고 관리할 수 있도록 기술을 제공하는
          것으로 회원이 직접 NFT를 생성하고 관리하는 것을 원칙으로 하며, 회원의
          NFT 생성 및 관리를 대행하거나 위탁하여 운영하지 않습니다.
        </li>
      </ul>
      <ul id="fadaa4cb-af22-4e10-b96b-829fef4b03ba" class="bulleted-list">
        <li style="list-style-type: disc">
          생성자가 서비스를 통해 생성한 NFT에 대한 내용, 목적 등에 대한 보증 및
          이행 의무는 회원에게 있으며, 회사는 회원이 생성한 NFT에 대하여 어떠한
          보증을 하거나 일체의 책임을 지지 않습니다.
        </li>
      </ul>
      <h3 id="fe7a4e6d-54e9-4433-b05d-722d041c6521" class="">
        <strong>제13조 판매서비스의 이용</strong>
      </h3>
      <ul id="de28d97b-4669-4010-ba18-505b4ad790bf" class="bulleted-list">
        <li style="list-style-type: disc">
          회사가 제공하는 판매서비스에는 일반판매, 제시판매가 있으며 서비스의
          종류와 내용은 변경될 수 있습니다. 회원은 회사가 서비스화면 등을 통해
          공지한 판매 특성, 절차 및 제시방법 등 판매에 관한 사항을 사전에 충분히
          이해하고 판매서비스를 이용하여야 합니다.
        </li>
      </ul>
      <ul id="6cd495ed-268a-47f1-bea6-35b666384567" class="bulleted-list">
        <li style="list-style-type: disc">
          회원은 메타마스크 DID 인증 등 회사가 제공하는 방식을 통해 판매서비스를
          이용할 수 있습니다.
        </li>
      </ul>
      <ul id="21e982a9-ff9d-4235-a7d7-8d128ee54895" class="bulleted-list">
        <li style="list-style-type: disc">
          일반 판매서비스를 이용해 NFT를 판매할 때 소요되는 블록체인 이용료(Gas
          Fee)와 플랫폼 이용 수수료 등 제반 비용은 판매자가 부담하여야 합니다.
          다만, 서비스 이용 편의를 위해 회사는 판매 후 결제서비스를 통해 플랫폼
          이용 수수료와 크리에이터 로열티(토큰 로열티), 프로젝트 로열티(컬렉션
          로열티)를 제외한 결제 토큰을 판매자에 제공할 수 있습니다.
        </li>
      </ul>
      <ul id="75191b7c-d3a7-4053-841d-24bacb690b8c" class="bulleted-list">
        <li style="list-style-type: disc">
          제시 판매서비스를 이용해 NFT를 제시할 때 소요되는 블록체인 이용료(Gas
          Fee)는 구매자가 부담하여야 합니다.
        </li>
      </ul>
      <ul id="5625f1ac-63f4-44d9-a325-e1c50b10362b" class="bulleted-list">
        <li style="list-style-type: disc">
          회원이 판매서비스를 통하여 NFT를 거래하는 경우, 반드시 회사가 제공하는
          판매서비스를 통해 거래를 완료하여야 합니다. 회사는 회원 간 직거래로
          인하여 발생한 문제에 대하여 책임지지 않습니다.
        </li>
      </ul>
      <ul id="05960498-bc35-4320-aef7-1057b0b544bb" class="bulleted-list">
        <li style="list-style-type: disc">
          판매자가 제공한 판매 정보에 관한 책임은 판매자가 부담합니다.
        </li>
      </ul>
      <ul id="208e1438-e544-40bb-aa1f-ed898f783a2d" class="bulleted-list">
        <li style="list-style-type: disc">
          회사는 중개 업체이므로 세무 처리의 주체가 아니며, 세금과 관련한 자문을
          제공하지 않습니다. NFT거래로 발생할 수 있는 소득세 등 제세공과 사무는
          회원이 직접 확인 후 처리하여야 합니다.
        </li>
      </ul>
      <ul id="ff2ce207-0f92-426a-8f6a-a541628a528e" class="bulleted-list">
        <li style="list-style-type: disc">
          회사는 판매서비스를 통한 거래의 안전성과 신뢰성을 위하여 이 약관 7조
          3항에 따라 회원자격을 정지하거나 회원의 판매서비스의 이용을 제한하는
          등 필요한 조치를 취할 수 있습니다. 회원자격이 정지된 회원은 서비스
          이용에 제한을 받을 수 있습니다. 회사가 회원자격을 정지하거나
          판매서비스의 이용제한, 기타 필요한 조치를 취할 경우 회사는 이 약관에
          특별한 규정이 없는 한 사전에 당해 회원에게 서비스화면 또는 이메일,
          기타의 방법을 통하여 통보하며, 회원의 연락이 두절되거나 긴급을 요하는
          등 부득이 한 경우 선 조치 후 통보할 수 있습니다. 판매서비스 이용 제한
          및 회원자격의 정지는 일시 또는 영구적일 수 있으며, 회원이 소명서를
          제출하는 등 회사가 정하는 기준을 충족하는 경우 이를 해소할 수
          있습니다.
        </li>
      </ul>
      <ul id="91f007ea-8aab-4677-9305-c522931547ad" class="bulleted-list">
        <li style="list-style-type: disc">
          회원 재가입 시에도 이전에 부과된 서비스 이용 제한과 회원자격정지 등의
          조치는 그대로 유지됩니다.
        </li>
      </ul>
      <ul id="fe62ab97-16ee-48aa-a81d-f5b94ff66d7c" class="bulleted-list">
        <li style="list-style-type: disc">
          판매서비스 이용이 제한된 회원에게 완료되지 않은 판매가 있는 경우
          회사는 구매자의 보호를 위해 판매 중인 NFT가 서비스 상 노출되지 않도록
          할 수 있습니다. 그 이전에 입찰을 한 회원이 있을 경우 판매서비스 이용이
          제한된 회원은 7조 1항의 내용에 준하여 구매자의 보호를 위한 필요 조치를
          취해야 합니다. NFT가 판매에 부적합한 것으로 밝혀진 경우 판매서비스
          이용이 제한된 회원이 구매자의 손해에 대한 책임을 부담합니다. 이 경우
          회사는 손해를 입은 당사자에게 이용이 제한된 회원의 일부 정보를 제공할
          수 있습니다.
        </li>
      </ul>
      <h3 id="7d25deb8-f6b5-4ee8-abae-d6f46697de02" class="">
        <strong>제 14조 구매 서비스의 이용</strong>
      </h3>
      <ul id="0334501b-f200-48f5-a02a-0a1099c464c9" class="bulleted-list">
        <li style="list-style-type: disc">
          회사가 제공하는 구매서비스에는 구매 시 민팅(lazy minting)이 있으며
          서비스의 종류와 내용은 변경될 수 있습니다. 회원은 회사가 서비스화면
          등을 통해 공지한 구매 특성, 절차 및 구매 후 민팅 등 구매에 관한 사항을
          사전에 충분히 이해하고 구매서비스를 이용하여야 합니다.
        </li>
      </ul>
      <ul id="30e04cd0-4092-4589-a71b-8b3d18fea08a" class="bulleted-list">
        <li style="list-style-type: disc">
          구매서비스를 이용해 NFT 상품 구매 시 구매자는 블록체인 이용료(Gas
          Fee)를 지급해야 합니다.
        </li>
      </ul>
      <h3 id="80232ad3-e605-4aa6-a9e6-80fd4c6af9d4" class="">
        <strong>제 15조 대리 및 보증의 부인</strong>
      </h3>
      <ul id="977a42d0-b1b6-46ab-9c34-af768abbc1ab" class="bulleted-list">
        <li style="list-style-type: disc">
          회사는 통신판매중개자로서 회원 상호간의 거래를 위한 온라인
          거래장소(Marketplace)를 제공할 뿐이므로 NFT를 판매하거나 구매하는
          회원을 대리하지 않습니다. 또한, 회사의 어떠한 행위도 판매자 또는
          구매자를 대리하는 행위로 간주되지 않습니다.
        </li>
      </ul>
      <ul id="fedbc765-2114-45d8-9ff4-1f718c5fac12" class="bulleted-list">
        <li style="list-style-type: disc">
          회사는 판매서비스를 통하여 이루어지는 회원 간 NFT 판매 및 구매와
          관련하여 판매의사 또는 구매의사의 존부 및 진정성, NFT의 가치, 완전성,
          안전성, 적법성 및 타인의 권리에 대한 비침해성, 회원이 입력하는 정보 및
          그 정보를 통하여 링크된 URL에 게재된 자료의 진실성 등 일체에 대하여
          보증하지 아니하며, 이와 관련한 일체의 위험과 책임은 NFT거래 당사자인
          회원이 부담합니다.
        </li>
      </ul>
      <ul id="412c0dad-3944-4ed2-8b8b-6ec50c9507f4" class="bulleted-list">
        <li style="list-style-type: disc">
          회사는 회원이 게재하는 NFT설명 등의 제반 정보를 통제하거나 관리하지
          않습니다. 다만, 회사가 회원이 게재한 정보의 내용이 타인의 명예, 권리를
          침해하거나 위법하다고 판단하는 경우에 서비스 상 노출제한, 판매취소,
          판매중지, 기타 필요한 조치를 취할 수 있습니다.
        </li>
      </ul>
      <h3 id="2c3ea0ff-4ef7-4db2-bd1e-f399b5ca4346" class="">
        <strong>제16조 결제서비스의 이용</strong>
      </h3>
      <ul id="008ae527-cc28-4b4d-bfd5-e27e2096f6ee" class="bulleted-list">
        <li style="list-style-type: disc">
          회사는 NFT거래에 사용되는 결제 토큰 및 NFT의 수령, 임시 보관 및 송금
          업무로 이루어지는 결제서비스를 제공합니다. 이러한 결제서비스는
          중개서비스를 통하여 이루어지는 회원 간의 거래의 안전성과 신뢰성을
          도모하고 거래 당사자를 보호하기 위한 목적에서 제공됩니다. 회사는
          회원이 정한 조건으로 NFT거래와 결제 토큰 지급 및 수령이 이루어지는
          스마트컨트랙트의 실행을 지원하는 것이므로 판매자 또는 구매자를 대리,
          대행하거나 그 이행을 보조하는 것은 아닙니다.
        </li>
      </ul>
      <ul id="c421d2f2-8835-4068-a39f-b313e835ec39" class="bulleted-list">
        <li style="list-style-type: disc">
          명시적으로 회원의 직거래를 허용하지 않는 NFT에 대하여 결제서비스를
          통하지 않는 직거래를 유도하는 경우, 회사는 당해 거래의 취소, 판매 중지
          등 필요한 조치를 취할 수 있습니다.
        </li>
      </ul>
      <ul id="63566384-e1e3-4996-aeda-1387c6a3fa57" class="bulleted-list">
        <li style="list-style-type: disc">
          회사가 제공하는 결제서비스를 이용하지 않은 거래에 대해 회사는 일체의
          책임을 지지 않습니다.
        </li>
      </ul>
      <ul id="29bb35b4-6a25-4012-9631-283cd7eae82a" class="bulleted-list">
        <li style="list-style-type: disc">
          회사가 제공하는 결제 서비스를 오용, 악용함으로써 사회질서, 미풍양속을
          침해하거나 침해할 우려가 있거나, 회사가 결제서비스를 제공하기 어려운
          상황이 발생하는 경우 회사는 결제서비스의 일부 또는 전부를 제공하지
          않거나 이용을 제한할 수 있습니다.
        </li>
      </ul>
      <h3 id="93bed2a0-e80a-4606-9de1-7dbbd3fc19b9" class="">
        <strong>제17조 서비스 이용 수수료</strong>
      </h3>
      <ul id="604d83f0-98eb-4423-861d-b6a4121801a7" class="bulleted-list">
        <li style="list-style-type: disc">
          회원은 서비스 이용 시 다음 서비스 이용 수수료 표에 기재된 수수료를
          지급해야 합니다. 서비스 이용 수수료는 이를 부담하는 자의 서비스 화면
          또는 이용가이드에 명시되어 있습니다.&lt;서비스 이용 수수료&gt;
          <table id="79f85d75-0640-42c3-88ec-9119324bfbb2" class="simple-table">
            <tbody>
              <tr id="33745e0d-7db6-40dd-ac37-e63a843e7f48">
                <td id="Pq~R" class="">구분</td>
                <td id="T@]r" class="">수수료명</td>
                <td id="y}|{" class="">수수료(율)</td>
                <td id="LUhG" class="">수수료 지급 시점</td>
              </tr>
              <tr id="e1026b60-ad81-4cb3-8347-dc828daf9f12">
                <td id="Pq~R" class="">블록체인 이용료(Gas Fee)</td>
                <td id="T@]r" class="">블록체인 이용료(Gas Fee)</td>
                <td id="y}|{" class="">메타마스크, 레인보우 화면 참조</td>
                <td id="LUhG" class="">
                  제시하기, 판매 등록, NFT 생성 등 이더리움 블록체인 상 트랜잭션
                  발생 시
                </td>
              </tr>
              <tr id="0a6f3914-037c-400e-9111-c1a32b106197">
                <td id="Pq~R" class="">판매</td>
                <td id="T@]r" class="">판매 수수료</td>
                <td id="y}|{" class="">거래 금액의 1%</td>
                <td id="LUhG" class="">판매시</td>
              </tr>
              <tr id="2837eb16-0f05-4ab8-98aa-4269a40ea609">
                <td id="Pq~R" class="">크리에이터 로열티</td>
                <td id="T@]r" class="">토큰 로열티</td>
                <td id="y}|{" class="">
                  크리에이터가 설정한 로열티에 따라 부과 (0 ~ 50%)
                </td>
                <td id="LUhG" class="">판매시</td>
              </tr>
              <tr id="bbc11b91-2530-4578-872e-b3b7acd233c7">
                <td id="Pq~R" class="">프로젝트 로열티</td>
                <td id="T@]r" class="">컬렉션 로열티</td>
                <td id="y}|{" class="">
                  컬렉션 소유자가 설정한 로열티에 따라 부과 (0 ~ 30%)
                </td>
                <td id="LUhG" class="">판매시</td>
              </tr>
            </tbody>
          </table>
        </li>
      </ul>
      <ul id="df229167-5b96-43a3-8bfd-4812c29669d2" class="bulleted-list">
        <li style="list-style-type: disc">
          서비스 이용 수수료 조정은 회사의 재량이나, 회사는 수수료(율) 변경을
          하는 경우 제20조에 따른 방법으로 최소한 변경 14일 전에 공지합니다.
        </li>
      </ul>
      <ul id="311a1a1a-a605-44ad-a069-14cac1f76478" class="bulleted-list">
        <li style="list-style-type: disc">
          회원은 수수료(율) 변경에 동의하지 아니하는 경우 본 약관 제7조에 따라
          이용계약을 해지할 수 있으며, 고객이 수수료(율) 변경에 대한 공지를 받은
          날로부터 변경일 전의 영업일까지 계약해지의 의사표시를 하지 아니하는
          경우에는 변경에 동의한 것으로 봅니다.
        </li>
      </ul>
      <ul id="313ff62d-674f-4fb9-a408-c073fda93af0" class="bulleted-list">
        <li style="list-style-type: disc">
          회사는 특정조건에 해당하는 경우에는 서비스 이용 수수료를 회원과의
          협의에 의하여 정할 수 있습니다.
        </li>
      </ul>
      <h3 id="2aa45c57-24ae-47f2-8741-305af55bf372" class="">
        <strong>제18조 서비스 이용제한 등</strong>
      </h3>
      <ul id="88cc8564-b037-4fcf-a308-3258412e73ed" class="bulleted-list">
        <li style="list-style-type: disc">
          회사는 원활한 서비스 제공을 위하여 다음 제제기준표에 따라 회원의
          서비스 이용을 제한할 수 있습니다. 이용 제한은 회사의 합리적인 판단에
          따라 경고, 정지, 영구이용정지, 이용계약의 해지 등으로 단계적으로
          이루어질 수 있습니다.&lt;제재기준표&gt;※ 위 표에 기재되지 않은
          사항이라 하더라도 각 호에 준하는 사유가 발생하거나 그러한 사유 발생의
          방지를 위한 조치가 필요한 경우 그 경중에 따라 제재가 적용될 수
          있습니다.
          <table id="97dbcaf2-b818-49c3-992f-de613aa15b35" class="simple-table">
            <tbody>
              <tr id="8fcf85b6-f239-4fc7-a074-fe0807b2c2b2">
                <td id="B^MG" class="">유형</td>
                <td id="t}GN" class="">유형 상 내용</td>
                <td id="R\k=" class="">서비스 이용 제재 기간</td>
              </tr>
              <tr id="f22c9660-220d-4463-bd62-eb83ce2d75d5">
                <td id="B^MG" class="">1차</td>
                <td id="t}GN" class="">2차</td>
                <td id="R\k=" class="">3차</td>
              </tr>
              <tr id="10af6400-2c5f-4d37-9b39-b3b59aa9e78c">
                <td id="B^MG" class="">계정</td>
                <td id="t}GN" class="">
                  1. 회원이 계속해서 1년 이상 로그인 하지 않은 경우
                </td>
                <td id="R\k=" class="">정지</td>
              </tr>
              <tr id="76f37b4c-6d85-444d-9cd5-a4d53bc15278">
                <td id="B^MG" class="">계정 도용</td>
                <td id="t}GN" class="">2. 8조 3항에 해당하는 경우</td>
                <td id="R\k=" class="">정지</td>
              </tr>
              <tr id="5c624172-a15a-407c-a420-f927fd396d61">
                <td id="B^MG" class="">3. 명의 도용으로 의심되는 경우</td>
                <td id="t}GN" class="">정지</td>
                <td id="R\k=" class=""></td>
              </tr>
              <tr id="205268fc-6b2e-4ab8-85e5-3b4ec5ad7aa0">
                <td id="B^MG" class="">범법행위</td>
                <td id="t}GN" class="">
                  4. 해킹 사고 또는 사기 행위가 발생한 경우
                </td>
                <td id="R\k=" class="">정지</td>
              </tr>
              <tr id="1ffb9bf7-84d5-49fc-bf6f-f7c0c66d7b38">
                <td id="B^MG" class="">
                  5. 자금세탁, 불공정거래, 범죄행위 등에 관여하고 있거나
                  관여하고 있다고 합리적으로 의심되는 경우
                </td>
                <td id="t}GN" class="">정지</td>
                <td id="R\k=" class=""></td>
              </tr>
              <tr id="06ff98c6-a48d-435d-b274-5858a18d504c">
                <td id="B^MG" class="">저작권 침해</td>
                <td id="t}GN" class="">
                  6. 회원이 생성한 NFT가 제3자의 저작권 등 기타 권리를 침해하는
                  경우
                </td>
                <td id="R\k=" class="">경고</td>
              </tr>
              <tr id="c7a88aa2-86e4-4258-bab4-3b930932f116">
                <td id="B^MG" class="">서비스 운영 방해</td>
                <td id="t}GN" class="">
                  7. 회원이 약관의 의무를 위반하거나 서비스의 정상적인 운영을
                  방해한 경우
                </td>
                <td id="R\k=" class="">경고</td>
              </tr>
              <tr id="6266ef08-511c-4cd2-9300-d85cf638d345">
                <td id="B^MG" class="">8. 제12조 3항에 해당하는 경우</td>
                <td id="t}GN" class="">경고</td>
                <td id="R\k=" class="">경고</td>
              </tr>
              <tr id="b45a4503-94fd-48c9-ae9e-30e9cbe84237">
                <td id="B^MG" class="">기타</td>
                <td id="t}GN" class="">
                  9. 이용 계약 당사자가 서비스 이용을 제한하는 특정 국가의
                  외국인이거나 민법상 미성년자일 경우
                </td>
                <td id="R\k=" class="">이용계약 해지</td>
              </tr>
              <tr id="e0bb0055-391a-40b9-b887-b29b4ae6aae8">
                <td id="B^MG" class="">
                  10. 국가기관이 관련 법령에 따라 이용자의 서비스 제한을 요청한
                  경우
                </td>
                <td id="t}GN" class="">정지</td>
                <td id="R\k=" class=""></td>
              </tr>
            </tbody>
          </table>
        </li>
      </ul>
      <ul id="c4b6a046-80dc-4edd-a963-ba85d043261d" class="bulleted-list">
        <li style="list-style-type: disc">
          전항에 따른 서비스 이용에 제한이 발생하는 경우, 본 약관에 따라 서비스
          및 회원과 관련 NFT 이용제한 조치가 적용될 수 있습니다.
        </li>
      </ul>
      <ul id="c57ace63-f482-463c-b620-8d3148d15f9d" class="bulleted-list">
        <li style="list-style-type: disc">
          회사는 본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용은
          운영정책, 이용안내 등에서 회사가 정한 바에 의합니다. 다만, 규정되지
          않은 경우 일반적인 온라인 서비스의 상식과 관례를 따릅니다.
        </li>
      </ul>
      <ul id="a51b126b-a42e-4e52-8be8-66cf1531bdd8" class="bulleted-list">
        <li style="list-style-type: disc">
          본 조에 따라 서비스 이용 제재조치를 하는 경우 회사는 제20조에 따른
          방법으로 당해 회원에게 통지합니다.
        </li>
      </ul>
      <ul id="71380873-f6b6-417b-a8d4-d755d2605fbe" class="bulleted-list">
        <li style="list-style-type: disc">
          회원은 본 조에 따른 이용제한 등에 대해 회사가 정한 절차에 따라
          플랫폼에서 제공하는 고객센터 혹은 이메일을 통해 이의신청을 할 수
          있습니다. 이 때 이의가 정당하다고 회사가 인정하는 경우 회사는 즉시
          당해 회원의 서비스 이용을 재개합니다.
        </li>
      </ul>
      <ul id="f3e4a3c2-5359-4860-b8f9-62261d8ccc04" class="bulleted-list">
        <li style="list-style-type: disc">
          본 조에 따른 이용제한은 회사의 회원에 대한 손해배상청구에 영향을
          미치지 않고, 회사는 필요시 수사기관의 사법적 대응과 연계하는 등 적극적
          조치를 취할 수 있습니다.
        </li>
      </ul>
      <h3 id="af7e6410-5927-468c-ac69-e6534dd7a387" class="">
        <strong>제19조 게시판의 이용</strong>
      </h3>
      <ul id="659edab8-4d6a-4e87-90ea-edcaa27b6a02" class="bulleted-list">
        <li style="list-style-type: disc">
          회사는 서비스 홈페이지에서 회원들이 지식을 공유할 수 있는 게시판을
          운영하거나, 일정한 자격을 가진 회원들이 이를 운영할 수 있도록 할 수
          있습니다. 게시물로 인한 저작권 침해 등 민사, 형사상의 모든 책임은 해당
          게시물의 작성자에게 있으며, 회사는 이에 대해 책임지지 않습니다.
        </li>
      </ul>
      <ul id="ebc182da-d61b-446a-9631-515685e5661e" class="bulleted-list">
        <li style="list-style-type: disc">
          회사의 게시판에 회원이 등록한 게시물의 저작권은 제 3 자의 권리를
          침해하지 않는 한, 게시물을 작성한 회원에게 속합니다. 다만, 적법한
          절차와 방법으로 작성된 게시물에 대해 회사는 다음과 같은 범위에서
          영구적인 권리를 가집니다. 그러나, 회사는 해당 게시물을 작성한 회원이
          게시물의 삭제와 사용중지를 요청한 경우에는 즉시 사용을 중지합니다.
          <ul id="31380d8f-924f-4a80-b73a-6f8e5d719320" class="bulleted-list">
            <li style="list-style-type: circle">
              서비스의 운영, 향상, 개선, 신규 서비스 개발, 프로모션 등을 위하여
              게시물을 사용, 편집, 저장, 복제, 수정, 공개, 전송, 공개적 실연,
              공개적인 게시, 배포할 수 있는 권리
            </li>
          </ul>
          <ul id="013a9f25-58ce-4562-b6c8-03cc7c0037e2" class="bulleted-list">
            <li style="list-style-type: circle">
              게시물의 2차적 저작물 제작 및 배포할 수 있는 권리
            </li>
          </ul>
          <ul id="760d46a8-1a25-4572-8733-9643897f4383" class="bulleted-list">
            <li style="list-style-type: circle">
              서비스 홍보를 위한 목적으로 미디어, 통신사 등이 게시물의 내용을
              보도, 방영하게 할 수 있는 권리
            </li>
          </ul>
        </li>
      </ul>
      <ul id="abd328a6-d470-47f2-bced-72adb893446f" class="bulleted-list">
        <li style="list-style-type: disc">
          회사는 다음 중 하나의 내용을 포함한 게시물의 작성을 금하며, 사전 통보
          없이 당해 게시물을 삭제하거나, 열람을 제한하거나, 작성자인 회원의
          게시물 추가 작성을 중지하거나 회원자격을 박탈할 수 있습니다.
          <ul id="c75aed53-e762-4020-9cdb-db0fbb507d2c" class="bulleted-list">
            <li style="list-style-type: circle">
              공공질서나 미풍양속에 위배되는 내용
            </li>
          </ul>
          <ul id="cae3aae4-e5a9-4343-a118-b4d1609ee6a7" class="bulleted-list">
            <li style="list-style-type: circle">
              불법물, 음란물 또는 청소년유해매체물의 게시, 등록 또는 이와 관련된
              물품의 홍보, 사이트 링크 등의 행위
            </li>
          </ul>
          <ul id="8b14f0c1-7b9e-43ea-acd6-0444c222812d" class="bulleted-list">
            <li style="list-style-type: circle">
              타인의 권리나 개인정보, 명예, 신용 기타 정당한 이익을 침해하는
              내용을 포함하는 경우
            </li>
          </ul>
          <ul id="71b6b9f3-d6cc-4406-8ad6-8c7ad2b14c60" class="bulleted-list">
            <li style="list-style-type: circle">
              범죄행위와 관련된 내용을 포함하는 경우
            </li>
          </ul>
          <ul id="5c063f8c-3bcf-4e6e-b2df-4944ce7a65c3" class="bulleted-list">
            <li style="list-style-type: circle">
              허위 또는 과장 광고 내용을 포함하는 경우
            </li>
          </ul>
          <ul id="a0ed9019-8cad-4312-9816-bf7fe5d51348" class="bulleted-list">
            <li style="list-style-type: circle">
              정치, 경제적 분쟁을 야기하는 내용을 포함하는 경우
            </li>
          </ul>
          <ul id="8e0a2fc2-16ce-497a-93c7-ed62bcc9cb89" class="bulleted-list">
            <li style="list-style-type: circle">
              정보통신 설비의 오동작을 유발하거나, 혼란을 유발하는 컴퓨터
              바이러스 또는 데이터를 포함하는 경우
            </li>
          </ul>
          <ul id="6ab15eca-1426-4ae6-a689-bcdcc68a4e19" class="bulleted-list">
            <li style="list-style-type: circle">
              NFT의 직거래를 유도하는 경우
            </li>
          </ul>
          <ul id="8621f4c4-ca38-4aff-b54b-7e3e9931a590" class="bulleted-list">
            <li style="list-style-type: circle">
              회사의 서비스 또는 기타 영업 행위의 원활한 진행을 방해한다고
              판단되는 경우
            </li>
          </ul>
          <ul id="9a64a95b-0efa-4c65-ba2f-9433b3e7bed6" class="bulleted-list">
            <li style="list-style-type: circle">
              NFT가 투자상품으로 해석되거나 투자 상품성 요소가 내포된 내용
            </li>
          </ul>
          <ul id="5d4fecf2-fdd9-445d-a471-f7b87b95a14c" class="bulleted-list">
            <li style="list-style-type: circle">
              기타 이 약관 또는 대한민국의 관련 법령에 위반되는 내용을 포함하는
              경우
            </li>
          </ul>
        </li>
      </ul>
      <ul id="121bb6f4-10ae-4569-a300-f23f832c41c4" class="bulleted-list">
        <li style="list-style-type: disc">
          회원은 서비스에 별도 표시되는 목적에 부합하는 한도에서 게시물을 자신의
          블로그나 개인 홈페이지 등에 퍼가기 할 수 있습니다. 이 경우 퍼가기 한
          게시물 에는 반드시 출처(URL 등)를 표시하고 당해 출처를 밝히는 링크를
          표시하여야 하며, 게시물의 동일성을 해하는 임의적인 변경을 할 수
          없습니다. 다만, 회사 또는 저작권자가 요구하는 경우 회원은 퍼가기 한
          게시물을 즉시 삭제하여야 합니다. 회원은 퍼가기 한 게시물에서 출처(URL
          등)로의 링크를 제거하거나 퍼가기 한 게시물을 다른 상업성 웹사이트에
          링크시킬 수 없습니다.
        </li>
      </ul>
      <ul id="7bc4eb72-2144-4945-9d41-5554f110b0c7" class="bulleted-list">
        <li style="list-style-type: disc">
          회원의 서비스 이용계약이 해지된 후에도 서비스의 운영 향상, 개선, 홍보
          등 제2항에 규정된 목적 범위 내에서 회사가 가지는 권리는 존속됩니다.
        </li>
      </ul>
      <h3 id="3f131b3f-ed9e-472f-b6b4-e57e1d3d9763" class="">
        <strong>제20조 회원에 대한 통지</strong>
      </h3>
      <ul id="6815f70f-3b84-46b2-83e9-2550f3a133bc" class="bulleted-list">
        <li style="list-style-type: disc">
          회사가 회원에 대하여 통지를 하는 경우 이 약관에 별도의 규정이 없는 한
          서비스 화면상에 게시하거나 회원이 제공한 전자우편주소 등으로 할 수
          있습니다.
        </li>
      </ul>
      <ul id="3c30ef25-3f83-43ff-bde8-c55df69d417e" class="bulleted-list">
        <li style="list-style-type: disc">
          회사는 회원 전체에 대하여 통지를 하는 경우 7일 이상 서비스 내 게시판에
          게시함으로써 전항의 통지에 갈음할 수 있습니다.
        </li>
      </ul>
      <h3 id="25b74f5d-0272-43a9-9b14-28a2ee0954e1" class="">
        <strong>제21조 저작권</strong>
      </h3>
      <ul id="3012c0eb-2f65-4cff-bbe8-db6f393047cc" class="bulleted-list">
        <li style="list-style-type: disc">
          회사가 제공하는 서비스 및 관련 프로그램의 저작권은 회사에게 있습니다.
        </li>
      </ul>
      <ul id="c8103960-9eb8-436f-91fb-a60c1cfa0508" class="bulleted-list">
        <li style="list-style-type: disc">
          회원은 서비스 이용 시 회사 또는 제3자의 저작권을 침해하여서는 안
          됩니다.
        </li>
      </ul>
      <ul id="3ff3b1f5-e22d-46e7-a033-20aa6ab7a5fb" class="bulleted-list">
        <li style="list-style-type: disc">
          회사의 서비스 이용 승낙이 구매자에게 NFT가 생성된 원본(본 약관 제4조
          가.항 4호 참조) 자체에 대한 지적재산권 및 소유권을 양도하는 것은
          아닙니다 .구매자가 입찰에 참가하여 NFT를 낙찰 받은 경우 구매자는
          판매자 혹은 원작자로 부터 NFT를 양도 받아 재양도와 전시 및 공유를 할
          수 있지만 NFT가 생성된 원본 그 자체에 대한 지적재산권 및 소유권은 양도
          받는 것이 아닙니다.
        </li>
      </ul>
      <ul id="4c62b602-79e5-42d7-9457-6f63ded8680b" class="bulleted-list">
        <li style="list-style-type: disc">
          회사는 서비스 이용과 관련된 NFT에 대하여 별도로 저작권을 가지지
          않습니다. 다만, 원활한 서비스 제공을 위한 범위에서 서비스에 이용된
          NFT에 대해 전시권 및 배포권을 가집니다.
        </li>
      </ul>
      <ul id="fafb8558-915e-4660-9510-d01ff18cdcd0" class="bulleted-list">
        <li style="list-style-type: disc">
          회사는 제4항에서 규정한 권리 외에 서비스 이용과 관련된 NFT와 관련된
          다른 권리(저작권, 동의 권한, 2차적 저작물 작성권 등)가 없으며, 이와
          관련된 법적 분 등 일체 문제에 대해 책임을 지지 않습니다.
        </li>
      </ul>
      <h3 id="ddc6b2c4-abbb-4ef7-991f-6dbe387e094a" class="">
        <strong>제22조 책임제한</strong>
      </h3>
      <ul id="170c5233-8e68-40e8-9316-925f3559385e" class="bulleted-list">
        <li style="list-style-type: disc">
          다음과 같은 사유로 회원에게 손해가 발생하였을 경우, 회사의 고의 또는
          과실에 의한 경우가 아닌 경우에는 그 손해에 대하여 책임을 지지
          않습니다.
          <ul id="fd43d92f-c6b5-4d13-b0af-c3653d3d63ac" class="bulleted-list">
            <li style="list-style-type: circle">
              천재지변 또는 이에 준하는 국가 비상사태 등으로 인하여 서비스를
              제공할 수 없는 경우
            </li>
          </ul>
          <ul id="590f0154-95d7-417e-af2e-99440dc62858" class="bulleted-list">
            <li style="list-style-type: circle">
              정부 기관의 사실상 또는 법률상 행정처분 및 명령 등에 대한 준수로
              인하여 서비스 이용이 불가능하게 되었거나 불이익이 발생한 경우
            </li>
          </ul>
          <ul id="cce24398-f47d-43fe-a31d-6aaa02523bad" class="bulleted-list">
            <li style="list-style-type: circle">
              블록체인 플랫폼의 장애 또는 회원의 귀책사유로 인하여 서비스 장애가
              발생한 경우
            </li>
          </ul>
          <ul id="9072b87f-8d4f-4296-8aa6-519b24ec6aac" class="bulleted-list">
            <li style="list-style-type: circle">
              순간적인 서비스 접속 증가 등으로 인한 서버의 장애가 발생한 경우
            </li>
          </ul>
          <ul id="6a943976-8971-4aa1-8ef2-f1964b4210c3" class="bulleted-list">
            <li style="list-style-type: circle">
              회원이 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성
              및 완전성 등의 내용에 관해 변조, 허위 등의 사실이 확인된 경우
            </li>
          </ul>
          <ul id="9b23d22f-20c8-43e1-8286-aaecea283176" class="bulleted-list">
            <li style="list-style-type: circle">
              회원 간 또는 회원과 제3자 상호 간에 직거래 등 회사가 제공하는
              서비스를 정상적으로 이용하지 않고 거래 등을 한 경우
            </li>
          </ul>
          <ul id="d4df184b-9479-491a-9e33-d25a138158ef" class="bulleted-list">
            <li style="list-style-type: circle">
              계약해지(회원탈퇴)를 하거나, 잘못된 계정 주소로 디지털 자산을
              입금하거나 NFT를 전달하는 등 회원의 과실 및 귀책으로 인한 손해가
              발생한 경우
            </li>
          </ul>
          <ul id="fca15222-3dab-4488-8421-d6c6016445e6" class="bulleted-list">
            <li style="list-style-type: circle">
              디지털 자산의 가격 변동으로 회원의 상품 가치가 달라진 경우
            </li>
          </ul>
          <ul id="8933fbcb-a628-454e-9f62-0e9f6a4b91dc" class="bulleted-list">
            <li style="list-style-type: circle">
              서비스에 대해 회사와 회원 간의 사전 합의 및 계약이 이루어지지
              않거나 해당 합의/계약의 범위를 벗어나 발생한 문제의 경우
            </li>
          </ul>
          <ul id="6a7f128c-f31b-4dc7-bcd2-2c204935b206" class="bulleted-list">
            <li style="list-style-type: circle">
              본 약관에 따른 이용제한 및 이용정지 조치로 인한 손해가 발생하는
              경우
            </li>
          </ul>
        </li>
      </ul>
      <ul id="df1bffc9-b0f2-4945-bbc1-ff6fce72e281" class="bulleted-list">
        <li style="list-style-type: disc">
          회사는 회원이 서비스를 이용하여 기대하는 이익을 얻지 못하거나 상실한
          것에 대하여 책임을 지지 않습니다.
        </li>
      </ul>
      <ul id="454d7e65-146c-4c95-8132-dee6761d6c5f" class="bulleted-list">
        <li style="list-style-type: disc">
          회사는 법령상 허용되는 한도 내에서 서비스와 관련하여 본 약관에
          명시되지 않은 어떠한 사항에 대해서도 직간접적인 약정이나 보증을 하지
          않습니다. 또한, 회사는 서비스를 통해 생성된 NFT 등 디지털 자산의
          가치를 보증하지 않습니다.
        </li>
      </ul>
      <ul id="22662d47-f0a7-48c1-8f73-6ebbd86980be" class="bulleted-list">
        <li style="list-style-type: disc">
          회사는 결제서비스에 이용된 디지털 자산 등을 포함한 서비스와 관련된
          모든 디지털 자산 가치를 보증하지 않습니다.
        </li>
      </ul>
      <ul id="fea465ec-897e-407b-a27c-58ed1e4b0cc1" class="bulleted-list">
        <li style="list-style-type: disc">
          회사는 회원이 서비스를 이용하여 생성한 NFT가 제3자의 권리를 침해하는
          경우 회사의 고의 또는 과실에 의한 경우가 아닌 경우에는 그 손해에
          대하여 책임을 지지 않습니다.
        </li>
      </ul>
      <h3 id="3277b401-1c54-4eef-940b-37619401bec3" class="">
        <strong>제23조 손해배상</strong>
      </h3>
      <ul id="9c009229-1858-4516-a168-9cc1ad038758" class="bulleted-list">
        <li style="list-style-type: disc">
          회사는 법령상 허용되는 한도 내에서 서비스와 관련하여 본 약관에
          명시되지 않은 어떠한 구체적인 사항에 대한 약정이나 보증을 하지
          않습니다. 또한, 회사는 회원의 서비스 이용 등으로 서비스에 게재된 정보,
          자료, 사실의 신뢰도, 정확성 등에 대해서는 보증을 하지 않으며, 회사의
          과실 없이 발생된 회원의 손해에 대하여는 책임을 부담하지 아니합니다.
        </li>
      </ul>
      <ul id="b936f340-e860-4bd9-bec3-876509559952" class="bulleted-list">
        <li style="list-style-type: disc">
          회사는 회사의 과실로 인하여 회원이 손해를 입게 될 경우 본 약관 및 관련
          법령에 따라 회원의 손해를 배상합니다. 다만 회사는 회사의 과실 없이
          발생된 아래와 같은 손해에 대해서는 책임을 부담하지 않습니다. 또한
          회사는 법률상 허용되는 한도 내에서 간접 손해, 특별 손해, 결과적 손해,
          징계적 손해, 및 징벌적 손해에 대한 책임을 부담하지 않습니다.
          <ul id="2efda1b5-efc2-46c2-ade0-e346df656be5" class="bulleted-list">
            <li style="list-style-type: circle">
              천재지변 또는 이에 준하는 불가항력의 상태에서 발생한 손해
            </li>
          </ul>
          <ul id="b91537bf-7464-4b75-a85b-eb38acd71473" class="bulleted-list">
            <li style="list-style-type: circle">
              회원의 귀책사유로 서비스 이용에 장애가 발생한 경우
            </li>
          </ul>
          <ul id="99efe156-894c-4d41-a42a-a2f7ce3da64c" class="bulleted-list">
            <li style="list-style-type: circle">
              서비스에 접속 또는 이용과정에서 발생하는 개인적인 손해
            </li>
          </ul>
          <ul id="47dc71d1-3100-442a-9b01-abbbd0ac3bf5" class="bulleted-list">
            <li style="list-style-type: circle">
              제3자가 불법적으로 회사의 서버에 접속하거나 서버를 이용함으로써
              발생하는 손해
            </li>
          </ul>
          <ul id="18991fa7-a5cf-43bd-af6f-4ae665a66e2d" class="bulleted-list">
            <li style="list-style-type: circle">
              제3자가 회사 서버에 대한 전송 또는 회사 서버로부터의 전송을
              방해함으로써 발생하는 손해
            </li>
          </ul>
          <ul id="170a22c6-d2c2-4fa6-8a73-4265d483bafb" class="bulleted-list">
            <li style="list-style-type: circle">
              제3자가 악성 프로그램을 전송 또는 유포함으로써 발생하는 손해
            </li>
          </ul>
          <ul id="066aba20-7d91-445f-9b06-8b0a12ebbbbc" class="bulleted-list">
            <li style="list-style-type: circle">
              전송된 데이터의 생략, 누락, 파괴 등으로 발생한 손해, 명예훼손 등
              제3자가 서비스를 이용하는 과정에서 발생된 손해
            </li>
          </ul>
          <ul id="804658d3-4598-4123-a009-65a7e1ae0fd9" class="bulleted-list">
            <li style="list-style-type: circle">
              기타 회사의 고의 또는 과실이 없는 사유로 인해 발생한 손해
            </li>
          </ul>
        </li>
      </ul>
      <ul id="1d502fc2-fa6e-46dd-905c-e977ee15a604" class="bulleted-list">
        <li style="list-style-type: disc">
          전항에도 불구하고 다음 각 호의 어느 하나에 해당하는 경우 회원이 그
          책임의 전부 또는 일부를 부담할 수 있습니다.
          <ul id="88887191-856a-4e4a-9dda-f0fe75c9e85f" class="bulleted-list">
            <li style="list-style-type: circle">
              회원이 손해 발생의 원인 또는 손해 발생 사실을 알았음에도 불구하고
              회사에 즉시 통지하지 않은 경우
            </li>
          </ul>
          <ul id="5b6baa4f-3ccf-4200-b55b-6f19b8cbd2ff" class="bulleted-list">
            <li style="list-style-type: circle">
              회원이 고의 또는 과실로 제3자에게 계정 및 거래 정보를 유출하거나
              서비스를 임의로 이용하게 한 경우
            </li>
          </ul>
          <ul id="3fa0e53e-5c72-4a2f-9f1f-e7c149c0d9cb" class="bulleted-list">
            <li style="list-style-type: circle">
              회원이 회사에 제공한 정보가 사실과 다를 경우
            </li>
          </ul>
          <ul id="db5d96ac-b5b7-46b2-87e7-1556c5b17090" class="bulleted-list">
            <li style="list-style-type: circle">
              회원이 회사의 서비스 또는 관련 시스템 운영을 방해하는 등 고의 또는
              과실로 법령, 본 약관, 사회질서에 위배하는 행위 등을 통하여 회사에
              손해를 발생시킨 경우
            </li>
          </ul>
        </li>
      </ul>
      <h3 id="d6d9a895-135f-494e-8f1d-a705743cad04" class="">
        <strong>제24조 준거법 및 재판관할</strong>
      </h3>
      <ul id="973ef858-4579-4738-b4cf-f4dc607a7ca1" class="bulleted-list">
        <li style="list-style-type: disc">
          회사와 회원 간 제기된 소송은 대한민국 법을 준거법으로 합니다.
        </li>
      </ul>
      <ul id="8cac84d4-13af-41b4-9abe-ba8a3d35cf13" class="bulleted-list">
        <li style="list-style-type: disc">
          회사와 회원 간 발생한 분쟁에 관한 소송은 민사소송법 상의 관할법원에
          제기합니다.
        </li>
      </ul>
    </div>
  </article>
</template>

<script>
export default {
  name: 'Tos',
}
</script>

<style scoped>
html,
body {
  margin: 0;
  padding: 0;
}
@media only screen {
  body {
    margin: 2em auto;
    max-width: 900px;
    color: rgb(55, 53, 47);
  }
}

body {
  line-height: 1.5;
  white-space: pre-wrap;
}

a,
a.visited {
  color: inherit;
  text-decoration: underline;
}

.pdf-relative-link-path {
  font-size: 80%;
  color: #444;
}

h1,
h2,
h3 {
  letter-spacing: -0.01em;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 0;
}

.page-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0.75em;
}

h1 {
  font-size: 1.875rem;
  margin-top: 1.875rem;
}

h2 {
  font-size: 1.5rem;
  margin-top: 1.5rem;
}

h3 {
  font-size: 1.25rem;
  margin-top: 1.25rem;
}

.source {
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 1.5em;
  word-break: break-all;
}

.callout {
  border-radius: 3px;
  padding: 1rem;
}

figure {
  margin: 1.25em 0;
  page-break-inside: avoid;
}

figcaption {
  opacity: 0.5;
  font-size: 85%;
  margin-top: 0.5em;
}

mark {
  background-color: transparent;
}

.indented {
  padding-left: 1.5em;
}

hr {
  background: transparent;
  display: block;
  width: 100%;
  height: 1px;
  visibility: visible;
  border: none;
  border-bottom: 1px solid rgba(55, 53, 47, 0.09);
}

img {
  max-width: 100%;
}

@media only print {
  img {
    max-height: 100vh;
    object-fit: contain;
  }
}

@page {
  margin: 1in;
}

.collection-content {
  font-size: 0.875rem;
}

.column-list {
  display: flex;
  justify-content: space-between;
}

.column {
  padding: 0 1em;
}

.column:first-child {
  padding-left: 0;
}

.column:last-child {
  padding-right: 0;
}

.table_of_contents-item {
  display: block;
  font-size: 0.875rem;
  line-height: 1.3;
  padding: 0.125rem;
}

.table_of_contents-indent-1 {
  margin-left: 1.5rem;
}

.table_of_contents-indent-2 {
  margin-left: 3rem;
}

.table_of_contents-indent-3 {
  margin-left: 4.5rem;
}

.table_of_contents-link {
  text-decoration: none;
  opacity: 0.7;
  border-bottom: 1px solid rgba(55, 53, 47, 0.18);
}

table,
th,
td {
  border: 1px solid rgba(55, 53, 47, 0.09);
  border-collapse: collapse;
}

table {
  border-left: none;
  border-right: none;
}

th,
td {
  font-weight: normal;
  padding: 0.25em 0.5em;
  line-height: 1.5;
  min-height: 1.5em;
  text-align: left;
}

th {
  color: rgba(55, 53, 47, 0.6);
}

ol,
ul {
  margin: 0;
  margin-block-start: 0.6em;
  margin-block-end: 0.6em;
}

li > ol:first-child,
li > ul:first-child {
  margin-block-start: 0.6em;
}

ul > li {
  list-style: disc;
}

ul.to-do-list {
  padding-inline-start: 0;
}

ul.to-do-list > li {
  list-style: none;
}

.to-do-children-checked {
  text-decoration: line-through;
  opacity: 0.375;
}

ul.toggle > li {
  list-style: none;
}

ul {
  padding-inline-start: 1.7em;
}

ul > li {
  padding-left: 0.1em;
}

ol {
  padding-inline-start: 1.6em;
}

ol > li {
  padding-left: 0.2em;
}

.mono ol {
  padding-inline-start: 2em;
}

.mono ol > li {
  text-indent: -0.4em;
}

.toggle {
  padding-inline-start: 0em;
  list-style-type: none;
}

/* Indent toggle children */
.toggle > li > details {
  padding-left: 1.7em;
}

.toggle > li > details > summary {
  margin-left: -1.1em;
}

.selected-value {
  display: inline-block;
  padding: 0 0.5em;
  background: rgba(206, 205, 202, 0.5);
  border-radius: 3px;
  margin-right: 0.5em;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  white-space: nowrap;
}

.collection-title {
  display: inline-block;
  margin-right: 1em;
}

.simple-table {
  margin-top: 1em;
  font-size: 0.875rem;
  empty-cells: show;
}
.simple-table td {
  height: 29px;
  min-width: 120px;
}

.simple-table th {
  height: 29px;
  min-width: 120px;
}

.simple-table-header-color {
  background: rgb(247, 246, 243);
  color: black;
}
.simple-table-header {
  font-weight: 500;
}

time {
  opacity: 0.5;
}

.icon {
  display: inline-block;
  max-width: 1.2em;
  max-height: 1.2em;
  text-decoration: none;
  vertical-align: text-bottom;
  margin-right: 0.5em;
}

img.icon {
  border-radius: 3px;
}

.user-icon {
  width: 1.5em;
  height: 1.5em;
  border-radius: 100%;
  margin-right: 0.5rem;
}

.user-icon-inner {
  font-size: 0.8em;
}

.text-icon {
  border: 1px solid #000;
  text-align: center;
}

.page-cover-image {
  display: block;
  object-fit: cover;
  width: 100%;
  max-height: 30vh;
}

.page-header-icon {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.page-header-icon-with-cover {
  margin-top: -0.72em;
  margin-left: 0.07em;
}

.page-header-icon img {
  border-radius: 3px;
}

.link-to-page {
  margin: 1em 0;
  padding: 0;
  border: none;
  font-weight: 500;
}

p > .user {
  opacity: 0.5;
}

td > .user,
td > time {
  white-space: nowrap;
}

input[type='checkbox'] {
  transform: scale(1.5);
  margin-right: 0.6em;
  vertical-align: middle;
}

p {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.image {
  border: none;
  margin: 1.5em 0;
  padding: 0;
  border-radius: 0;
  text-align: center;
}

.code,
code {
  background: rgba(135, 131, 120, 0.15);
  border-radius: 3px;
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-size: 85%;
  tab-size: 2;
}

code {
  color: #eb5757;
}

.code {
  padding: 1.5em 1em;
}

.code-wrap {
  white-space: pre-wrap;
  word-break: break-all;
}

.code > code {
  background: none;
  padding: 0;
  font-size: 100%;
  color: inherit;
}

blockquote {
  font-size: 1.25em;
  margin: 1em 0;
  padding-left: 1em;
  border-left: 3px solid rgb(55, 53, 47);
}

.bookmark {
  text-decoration: none;
  max-height: 8em;
  padding: 0;
  display: flex;
  width: 100%;
  align-items: stretch;
}

.bookmark-title {
  font-size: 0.85em;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 1.75em;
  white-space: nowrap;
}

.bookmark-text {
  display: flex;
  flex-direction: column;
}

.bookmark-info {
  flex: 4 1 180px;
  padding: 12px 14px 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bookmark-image {
  width: 33%;
  flex: 1 1 180px;
  display: block;
  position: relative;
  object-fit: cover;
  border-radius: 1px;
}

.bookmark-description {
  color: rgba(55, 53, 47, 0.6);
  font-size: 0.75em;
  overflow: hidden;
  max-height: 4.5em;
  word-break: break-word;
}

.bookmark-href {
  font-size: 0.75em;
  margin-top: 0.25em;
}

.sans {
  font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Helvetica, 'Apple Color Emoji', Arial, sans-serif, 'Segoe UI Emoji',
    'Segoe UI Symbol';
}
.code {
  font-family: 'SFMono-Regular', Menlo, Consolas, 'PT Mono', 'Liberation Mono',
    Courier, monospace;
}
.serif {
  font-family: Lyon-Text, Georgia, ui-serif, serif;
}
.mono {
  font-family: iawriter-mono, Nitti, Menlo, Courier, monospace;
}
.pdf .sans {
  font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Helvetica, 'Apple Color Emoji', Arial, sans-serif,
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Twemoji', 'Noto Color Emoji',
    'Noto Sans CJK JP';
}
.pdf:lang(zh-CN) .sans {
  font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Helvetica, 'Apple Color Emoji', Arial, sans-serif,
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Twemoji', 'Noto Color Emoji',
    'Noto Sans CJK SC';
}
.pdf:lang(zh-TW) .sans {
  font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Helvetica, 'Apple Color Emoji', Arial, sans-serif,
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Twemoji', 'Noto Color Emoji',
    'Noto Sans CJK TC';
}
.pdf:lang(ko-KR) .sans {
  font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Helvetica, 'Apple Color Emoji', Arial, sans-serif,
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Twemoji', 'Noto Color Emoji',
    'Noto Sans CJK KR';
}
.pdf .code {
  font-family: Source Code Pro, 'SFMono-Regular', Menlo, Consolas, 'PT Mono',
    'Liberation Mono', Courier, monospace, 'Twemoji', 'Noto Color Emoji',
    'Noto Sans Mono CJK JP';
}
.pdf:lang(zh-CN) .code {
  font-family: Source Code Pro, 'SFMono-Regular', Menlo, Consolas, 'PT Mono',
    'Liberation Mono', Courier, monospace, 'Twemoji', 'Noto Color Emoji',
    'Noto Sans Mono CJK SC';
}
.pdf:lang(zh-TW) .code {
  font-family: Source Code Pro, 'SFMono-Regular', Menlo, Consolas, 'PT Mono',
    'Liberation Mono', Courier, monospace, 'Twemoji', 'Noto Color Emoji',
    'Noto Sans Mono CJK TC';
}
.pdf:lang(ko-KR) .code {
  font-family: Source Code Pro, 'SFMono-Regular', Menlo, Consolas, 'PT Mono',
    'Liberation Mono', Courier, monospace, 'Twemoji', 'Noto Color Emoji',
    'Noto Sans Mono CJK KR';
}
.pdf .serif {
  font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, 'Twemoji',
    'Noto Color Emoji', 'Noto Serif CJK JP';
}
.pdf:lang(zh-CN) .serif {
  font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, 'Twemoji',
    'Noto Color Emoji', 'Noto Serif CJK SC';
}
.pdf:lang(zh-TW) .serif {
  font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, 'Twemoji',
    'Noto Color Emoji', 'Noto Serif CJK TC';
}
.pdf:lang(ko-KR) .serif {
  font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, 'Twemoji',
    'Noto Color Emoji', 'Noto Serif CJK KR';
}
.pdf .mono {
  font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace,
    'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK JP';
}
.pdf:lang(zh-CN) .mono {
  font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace,
    'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK SC';
}
.pdf:lang(zh-TW) .mono {
  font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace,
    'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK TC';
}
.pdf:lang(ko-KR) .mono {
  font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace,
    'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK KR';
}
.highlight-default {
  color: rgba(55, 53, 47, 1);
}
.highlight-gray {
  color: rgba(120, 119, 116, 1);
  fill: rgba(120, 119, 116, 1);
}
.highlight-brown {
  color: rgba(159, 107, 83, 1);
  fill: rgba(159, 107, 83, 1);
}
.highlight-orange {
  color: rgba(217, 115, 13, 1);
  fill: rgba(217, 115, 13, 1);
}
.highlight-yellow {
  color: rgba(203, 145, 47, 1);
  fill: rgba(203, 145, 47, 1);
}
.highlight-teal {
  color: rgba(68, 131, 97, 1);
  fill: rgba(68, 131, 97, 1);
}
.highlight-blue {
  color: rgba(51, 126, 169, 1);
  fill: rgba(51, 126, 169, 1);
}
.highlight-purple {
  color: rgba(144, 101, 176, 1);
  fill: rgba(144, 101, 176, 1);
}
.highlight-pink {
  color: rgba(193, 76, 138, 1);
  fill: rgba(193, 76, 138, 1);
}
.highlight-red {
  color: rgba(212, 76, 71, 1);
  fill: rgba(212, 76, 71, 1);
}
.highlight-gray_background {
  background: rgba(241, 241, 239, 1);
}
.highlight-brown_background {
  background: rgba(244, 238, 238, 1);
}
.highlight-orange_background {
  background: rgba(251, 236, 221, 1);
}
.highlight-yellow_background {
  background: rgba(251, 243, 219, 1);
}
.highlight-teal_background {
  background: rgba(237, 243, 236, 1);
}
.highlight-blue_background {
  background: rgba(231, 243, 248, 1);
}
.highlight-purple_background {
  background: rgba(244, 240, 247, 0.8);
}
.highlight-pink_background {
  background: rgba(249, 238, 243, 0.8);
}
.highlight-red_background {
  background: rgba(253, 235, 236, 1);
}
.block-color-default {
  color: inherit;
  fill: inherit;
}
.block-color-gray {
  color: rgba(120, 119, 116, 1);
  fill: rgba(120, 119, 116, 1);
}
.block-color-brown {
  color: rgba(159, 107, 83, 1);
  fill: rgba(159, 107, 83, 1);
}
.block-color-orange {
  color: rgba(217, 115, 13, 1);
  fill: rgba(217, 115, 13, 1);
}
.block-color-yellow {
  color: rgba(203, 145, 47, 1);
  fill: rgba(203, 145, 47, 1);
}
.block-color-teal {
  color: rgba(68, 131, 97, 1);
  fill: rgba(68, 131, 97, 1);
}
.block-color-blue {
  color: rgba(51, 126, 169, 1);
  fill: rgba(51, 126, 169, 1);
}
.block-color-purple {
  color: rgba(144, 101, 176, 1);
  fill: rgba(144, 101, 176, 1);
}
.block-color-pink {
  color: rgba(193, 76, 138, 1);
  fill: rgba(193, 76, 138, 1);
}
.block-color-red {
  color: rgba(212, 76, 71, 1);
  fill: rgba(212, 76, 71, 1);
}
.block-color-gray_background {
  background: rgba(241, 241, 239, 1);
}
.block-color-brown_background {
  background: rgba(244, 238, 238, 1);
}
.block-color-orange_background {
  background: rgba(251, 236, 221, 1);
}
.block-color-yellow_background {
  background: rgba(251, 243, 219, 1);
}
.block-color-teal_background {
  background: rgba(237, 243, 236, 1);
}
.block-color-blue_background {
  background: rgba(231, 243, 248, 1);
}
.block-color-purple_background {
  background: rgba(244, 240, 247, 0.8);
}
.block-color-pink_background {
  background: rgba(249, 238, 243, 0.8);
}
.block-color-red_background {
  background: rgba(253, 235, 236, 1);
}
.select-value-color-pink {
  background-color: rgba(245, 224, 233, 1);
}
.select-value-color-purple {
  background-color: rgba(232, 222, 238, 1);
}
.select-value-color-green {
  background-color: rgba(219, 237, 219, 1);
}
.select-value-color-gray {
  background-color: rgba(227, 226, 224, 1);
}
.select-value-color-opaquegray {
  background-color: rgba(255, 255, 255, 0.0375);
}
.select-value-color-orange {
  background-color: rgba(250, 222, 201, 1);
}
.select-value-color-brown {
  background-color: rgba(238, 224, 218, 1);
}
.select-value-color-red {
  background-color: rgba(255, 226, 221, 1);
}
.select-value-color-yellow {
  background-color: rgba(253, 236, 200, 1);
}
.select-value-color-blue {
  background-color: rgba(211, 229, 239, 1);
}

.checkbox {
  display: inline-flex;
  vertical-align: text-bottom;
  width: 16;
  height: 16;
  background-size: 16px;
  margin-left: 2px;
  margin-right: 5px;
}

.checkbox-on {
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Crect%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22%2358A9D7%22%2F%3E%0A%3Cpath%20d%3D%22M6.71429%2012.2852L14%204.9995L12.7143%203.71436L6.71429%209.71378L3.28571%206.2831L2%207.57092L6.71429%2012.2852Z%22%20fill%3D%22white%22%2F%3E%0A%3C%2Fsvg%3E');
}

.checkbox-off {
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Crect%20x%3D%220.75%22%20y%3D%220.75%22%20width%3D%2214.5%22%20height%3D%2214.5%22%20fill%3D%22white%22%20stroke%3D%22%2336352F%22%20stroke-width%3D%221.5%22%2F%3E%0A%3C%2Fsvg%3E');
}
</style>
